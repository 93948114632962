import { Button, Form, Input, Modal, Row, notification } from 'antd';
import PageHeader from 'components/PageHeader';
import { SEND_TEST_EMAIL } from 'graphql/mutations/emailSettingsMutations';
import { useMutation } from '@apollo/client';
import './TestMail.scss';

import * as yup from 'yup';

const schema = yup.object().shape({
  email: yup
    .string()
    .required('The send to is required.')
    .email('Please enter a valid email address.'),
});

const yupSync = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value });
  },
};

export const TestMailModal = ({ isOpen, onCancel, data, emailKey, formData }) => {
  const [form] = Form.useForm();

  const [sendTestEmail, { loading }] = useMutation(SEND_TEST_EMAIL);

  const onSettingUpdate = async (values) => {
    sendTestEmail({
      variables: {
        emailType: emailKey,
        email: values.email,
        template: {
          subjectLine: formData?.subjectLine,
          previewText: formData?.previewText,
          emailBody: formData?.emailBody,
          buttonText: formData?.buttonText,
          reminder: formData?.reminder,
        },
      },
      onCompleted: (data) => {
        if (data?.sendTestMail) {
          notification.success({ message: 'Success! Your test email has been sent.' });
          onCancel();
        }
      },
      onError: (error) => {
        notification.error({
          message: error.message || 'Unable to send test email. Please verify your details.',
        });
      },
    });
  };

  return isOpen ? (
    <Modal
      centered
      className="send-test-mail-modal"
      visible={isOpen}
      width={'530px'}
      onCancel={onCancel}
      footer={false}>
      <PageHeader pageTitle="Send Test Email" />
      <Form
        form={form}
        style={{ width: '100%' }}
        initialValues={data}
        layout="vertical"
        autoComplete="off"
        onFinish={onSettingUpdate}>
        <Row style={{ gap: '35px', flexDirection: 'column', marginTop: '25px' }}>
          <Form.Item
            name="email"
            label={<span className="heading">Send to</span>}
            rules={[yupSync]}>
            <Input />
          </Form.Item>
          <Row align="middle" style={{ gap: '20px' }} justify="center">
            <Button loading={loading} htmlType="submit" className="save-btn">
              Send Test
            </Button>
            <Button disabled={loading} onClick={onCancel} className="cancel-btn">
              Cancel
            </Button>
          </Row>
        </Row>
      </Form>
    </Modal>
  ) : (
    <></>
  );
};
