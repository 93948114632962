import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Col, Row } from 'antd';

import helpers from 'helpers/proposal';
import RichEditor from 'pages/Proposal/components/RichEditor/RichEditor';
import useWindowDimensions from 'hooks/useWindowDimensions';
import Loader from 'components/Loader';
import utils from '../../utils';
import commonUtils from 'utils/utils';
import TermsReviewMessage from '../TermsReviewMessage';

import './SimpleSection.scss';
import { useProposalContext } from 'context/proposal';
import { ELEMENTS_NOT_ALLOWED_IN_MULTI_COLUMN } from 'pages/Proposal/constants/constants';

const findSimpleSectionLayOut = ({ position, proposalTextCell }) => {
  const simpleSectionLayout = {
    simpleSectionTitleSpan: 8,
    simpleSectionItemsSpan: 16,
    sectionContainerStyle: {},
    headerStyle: {},
    proposalTextCell,
  };

  if (!position) return simpleSectionLayout;
  if (position) {
    if (position === 'top') {
      simpleSectionLayout.sectionContainerStyle = {
        display: 'flex',
      };
      simpleSectionLayout.headerStyle = {
        width: '100%',
      };
      simpleSectionLayout.simpleSectionTitleSpan = 24;
      simpleSectionLayout.simpleSectionItemsSpan = 24;
    } else if (position === 'top-middle') {
      simpleSectionLayout.sectionContainerStyle = {
        display: 'flex',
      };
      simpleSectionLayout.headerStyle = {
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      };
      simpleSectionLayout.simpleSectionTitleSpan = 24;
      simpleSectionLayout.simpleSectionItemsSpan = 24;
    } else if (position === 'none') {
      simpleSectionLayout.headerStyle = {
        display: 'none',
      };
      simpleSectionLayout.simpleSectionTitleSpan = 24;
      simpleSectionLayout.simpleSectionItemsSpan = 24;
    } else if (position === 'large') {
      simpleSectionLayout.simpleSectionTitleSpan = 4;
      simpleSectionLayout.simpleSectionItemsSpan = 20;
    }
  }
  return simpleSectionLayout;
};

const SimpleSection = ({
  name,
  prop,
  config,
  configText,
  element,
  setElement,
  elementValue,
  saveUser,
  saveProposalDraft,
  setDisablePreview,
  zoomValue,
  setSelectedBlock,
  updateLoaderState,
  // shouldUndoRedoUpdate,
  setOpenVariablesList,
  setScrollingTo,
  setSavingDraftProposal,
  dropDisabled,
  setDropDisabled,
  draggingElementIndex,
  setDraggingElementIndex,
  sectionTitlePosition,
  activeEditorId,
  setActiveEditorId,
  templateWixEditor,
  draggingElement,
  setElementsInSection,
  sectionDroppable,
  setLastHoveredSection,
  askAI,
  setAskAI,
}) => {
  const { isMobile, width: screenWidth } = useWindowDimensions();
  const { wixEditor, saveTemplateProposal: saveProposal } = useProposalContext();
  let fontSizeValue = 0;

  const [section, setSection] = useState('');
  const [fontSize, setFontSize] = useState('');
  const [titlePosition, setTitlePosition] = useState('');
  const [proposalTextCell, setProposalTextCell] = useState(
    helpers.getStyleObj(prop, 'body2', '', name)
  );

  useEffect(() => {
    if (sectionTitlePosition) {
      setTitlePosition(sectionTitlePosition);
    }
  }, [sectionTitlePosition]);

  /* eslint-disable */
  useEffect(() => {
    if (
      !section ||
      // shouldUndoRedoUpdate ||
      !_.isEqual(prop?.draft?.[name]?.raw, section?.raw) ||
      !_.isEqual(prop?.draft?.[name]?.rawtitle, section?.rawtitle) ||
      !_.isEqual(prop?.draft?.[name]?.subrawtitle, section?.subrawtitle) ||
      prop?.draft[name]?.numberOfColumns !== section?.numberOfColumns ||
      prop?.draft[name]?.typeOfColumns !== section?.typeOfColumns ||
      prop?.draft[name]?.titlePosition !== section?.titlePosition ||
      (section?.image && !section?.raw && prop?.draft?.[name]?.raw)
    ) {
      setSection(prop.draft[name]);
    }
  }, [
    prop.draft[name],
    // , shouldUndoRedoUpdate
  ]);

  useEffect(() => {
    setFontSize(prop?.draft?.bodyFont?.fontSize?.match(/\d+/)[0]);
    if (!sectionTitlePosition) {
      setTitlePosition(prop?.draft?.titleFont?.position);
    }
    setProposalTextCell(helpers.getStyleObj(prop, 'body2', '', name));
  }, [
    prop?.draft?.bodyFont?.fontSize,
    prop?.draft?.bodyFont?.family,
    prop?.draft?.bodyFont?.weight,
    prop?.draft?.bodyFont?.color2,
    prop?.draft?.titleFont?.position,
    element,
    elementValue,
    prop?.draft?.[name]?.typeOfColumns,
    prop?.draft?.[name]?.titlePosition,
    prop?.bodyFont?.fontSize,
    prop?.bodyFont?.family,
    prop?.bodyFont?.weight,
    prop?.bodyFont?.color2,
    prop?.titleFont?.position,
    prop?.sectionStyles,
    prop?.draft?.sectionStyles,
  ]);
  /* eslint-enable */

  if (fontSize && fontSize <= 14) {
    proposalTextCell.marginTop = '10px';
    proposalTextCell.letterSpacing = '1px';
  }

  // const simpleSectionLayout = findSimpleSectionLayOut({
  //   position: titlePosition,
  //   proposalTextCell,
  // });
  const simpleSectionLayout = useMemo(
    () =>
      findSimpleSectionLayOut({
        position: titlePosition,
        proposalTextCell,
      }),
    [titlePosition, proposalTextCell]
  );

  const getSpan = (span, index) => {
    if (span) {
      return span;
    } else {
      if (section.numberOfColumns === 2) {
        if (section.typeOfColumns === 'tworight') {
          if (index === 0) return 18;
          else return 6;
        } else if (section.typeOfColumns === 'twoleft') {
          if (index === 0) return 6;
          else return 18;
        } else {
          return 12;
        }
      } else if (section.numberOfColumns === 3) {
        return 8;
      } else if (section.numberOfColumns === 4) {
        return 6;
      } else {
        24;
      }
    }
  };

  const mainSection = (
    sectionName,
    raw,
    text,
    rawName,
    index,
    span,
    style,
    className,
    proposal
  ) => {
    const sectionStyle =
      style ||
      Object.assign(
        {},
        index === 0
          ? isMobile
            ? { paddingBottom: '8px' }
            : { paddingRight: '8px' }
          : index
          ? isMobile
            ? { paddingBottom: '8px' }
            : { paddingLeft: '8px' }
          : {}
      );
    const sectionSpan = getSpan(span, index);
    const isDropable = commonUtils.calculatePricingDropable(
      simpleSectionLayout.simpleSectionTitleSpan,
      sectionSpan
    );

    return (
      <Col
        span={sectionSpan}
        style={sectionStyle}
        className={className}
        key={`${sectionName}-${index}`}>
        {ELEMENTS_NOT_ALLOWED_IN_MULTI_COLUMN.includes(draggingElement) && !isDropable && (
          <div className="prevent-drop-mask" />
        )}

        <RichEditor
          placeholder={configText('enter section content', true) || ''}
          id={`${sectionName}_text_${index}`}
          sectionName={sectionName}
          columnIndex={index}
          numberOfColumns={section?.numberOfColumns || 1}
          sectionSpan={sectionSpan}
          titleSpan={simpleSectionLayout.simpleSectionTitleSpan}
          setElementsInSection={(v, type, key) => setElementsInSection(v, type, key, index)}
          prop={proposal}
          config={config}
          configText={configText}
          raw={raw}
          content={text}
          simple={0}
          element={element}
          setElement={setElement}
          elementValue={elementValue}
          sectionType={rawName}
          saveProposal={saveProposal}
          saveProposalDraft={saveProposalDraft}
          setDisablePreview={setDisablePreview}
          setSavingDraftProposal={setSavingDraftProposal}
          saveUser={saveUser}
          zoomValue={zoomValue}
          setSelectedBlock={setSelectedBlock}
          updateLoaderState={updateLoaderState}
          blockRenderMap={utils.getBlockRenderMap()}
          setOpenVariablesList={setOpenVariablesList}
          setScrollingTo={setScrollingTo}
          dropDisabled={
            // if dragging element, disable dragging element across column
            (typeof draggingElementIndex === 'number' &&
              (draggingElementIndex !== index || dropDisabled !== sectionName)) ||
            // disable dragging element across section
            (dropDisabled && dropDisabled !== sectionName)
              ? true
              : false
          }
          setDropDisabled={setDropDisabled}
          setDraggingElement={(type = 'start') => {
            setDraggingElementIndex(type === 'start' ? index : null);
          }}
          wixEditor={wixEditor}
          templateWixEditor={templateWixEditor}
          htmlOptions={{
            elementStyles: {
              right: 'RIGHT',
              xxx: 'XXX',
              yyy: 'YYY',
              totes: 'TOTES',
            },
          }}
          activeEditorId={activeEditorId}
          setActiveEditorId={setActiveEditorId}
          askAI={askAI}
          setAskAI={setAskAI}
        />
      </Col>
    );
  };

  const renderMainSection = useCallback(() => {
    if (section.numberOfColumns && section.numberOfColumns !== 1) {
      return (
        <Col
          span={simpleSectionLayout.simpleSectionItemsSpan}
          className="simple-section-content"
          style={{
            ...simpleSectionLayout.proposalTextCell,
            ...(screenWidth < 768 && { flexFlow: 'column' }),
          }}>
          {_.times(section.numberOfColumns, (index) =>
            mainSection(
              name,
              section[`${index === 0 ? 'raw' : `raw${index}`}`],
              section[`${index === 0 ? 'text' : `text${index}`}`],
              `${index === 0 ? 'raw' : `raw${index}`}`,
              index,
              undefined,
              undefined,
              undefined,
              prop
            )
          )}
        </Col>
      );
    } else {
      return mainSection(
        name,
        section.raw,
        section.text,
        'raw',
        0,
        simpleSectionLayout.simpleSectionItemsSpan,
        simpleSectionLayout.proposalTextCell,
        'simple-section-content',
        prop
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    section?.raw,
    section?.typeOfColumns,
    section.numberOfColumns,
    fontSize,
    titlePosition,
    proposalTextCell,
    // shouldUndoRedoUpdate,
    prop,
    screenWidth,
    askAI,
  ]);

  const renderSectionTitle = useCallback(
    () => (
      <RichEditor
        placeholder={configText('Section Title', true) || ''}
        prop={prop}
        id={`${name}_title`}
        content={section && section.title}
        raw={section && section.rawtitle}
        config={config}
        sectionName={name}
        sectionType="rawtitle"
        saveProposalDraft={saveProposalDraft}
        setDisablePreview={setDisablePreview}
        setSavingDraftProposal={setSavingDraftProposal}
        simple={2}
        blockRenderMap={null}
        zoomValue={zoomValue}
        setSelectedBlock={setSelectedBlock}
        setOpenVariablesList={setOpenVariablesList}
        setScrollingTo={setScrollingTo}
        dropDisabled={true}
        setActiveEditorId={setActiveEditorId}
        activeEditorId={activeEditorId}
        askAI={askAI}
        setAskAI={setAskAI}
        wixEditor={wixEditor}
        templateWixEditor={templateWixEditor}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      prop,
      section.title,
      section.rawtitle,
      saveProposalDraft,
      zoomValue,
      activeEditorId,
      askAI,
      setAskAI,
    ] //eslint-disable-line
  );

  const renderSectionSubTitle = useCallback(
    () => (
      <RichEditor
        placeholder={configText('Subtitle', true) || 'Subtitle'}
        prop={prop}
        id={`${name}_sub_title`}
        content={section.subtitle || ''}
        raw={section.subrawtitle || ''}
        sectionName={name}
        config={config}
        sectionType="subrawtitle"
        saveProposalDraft={saveProposalDraft}
        setDisablePreview={setDisablePreview}
        setSavingDraftProposal={setSavingDraftProposal}
        simple={2}
        blockRenderMap={null}
        zoomValue={zoomValue}
        setSelectedBlock={setSelectedBlock}
        setOpenVariablesList={setOpenVariablesList}
        setScrollingTo={setScrollingTo}
        dropDisabled={true}
        activeEditorId={activeEditorId}
        setActiveEditorId={setActiveEditorId}
        askAI={askAI}
        setAskAI={setAskAI}
        wixEditor={wixEditor}
        templateWixEditor={templateWixEditor}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      configText,
      prop,
      name,
      section.subtitle,
      section.subrawtitle,
      saveProposalDraft,
      zoomValue,
      activeEditorId,
      setActiveEditorId,
      config,
      askAI,
      setAskAI,
    ] //eslint-disable-line
  );

  const handleMouseOver = () => {
    if (!askAI) {
      setLastHoveredSection(name);
    }
  };

  if (!section) {
    return <Loader />;
  }

  return section.image ? (
    <div
      onMouseOver={handleMouseOver}
      id={name}
      className="simple-section v3-proposalblock-image"
      style={{
        padding: section.raw ? '7% 0% 0' : '5% 0% 0 0%',
        textAlign: '-webkit-center',
      }}>
      {draggingElement && <div className="prevent-drop-mask" />}

      <Row className="simple-section-container">
        <Col className="simple-section-content">
          <RichEditor
            placeholder={configText('enter section content', true) || 'Enter section content'}
            id={`${name}_image`}
            prop={prop}
            config={config}
            raw={section.raw}
            content={section.image}
            sectionName={name}
            numberOfColumns={section?.numberOfColumns || 1}
            sectionType="raw"
            saveProposal={saveProposal}
            saveProposalDraft={saveProposalDraft}
            setDisablePreview={setDisablePreview}
            setSavingDraftProposal={setSavingDraftProposal}
            contentType="image"
            simple={0}
            element={element}
            setElement={setElement}
            elementValue={elementValue}
            saveUser={saveUser}
            zoomValue={zoomValue}
            setSelectedBlock={setSelectedBlock}
            setOpenVariablesList={setOpenVariablesList}
            setScrollingTo={setScrollingTo}
            blockRenderMap={utils.getBlockRenderMap()}
            updateLoaderState={updateLoaderState}
            htmlOptions={{
              elementStyles: {
                right: 'RIGHT',
                xxx: 'XXX',
                yyy: 'YYY',
                totes: 'TOTES',
              },
            }}
            activeEditorId={activeEditorId}
            setActiveEditorId={setActiveEditorId}
          />
        </Col>
      </Row>
    </div>
  ) : (
    <div
      onMouseOver={handleMouseOver}
      id={name}
      className={`simple-section 
        ${titlePosition && `editor-layout-position-${titlePosition}`}`}
      style={fontSizeValue && fontSizeValue <= 14 ? { padding: '20px 50px 0px' } : {}}>
      {(wixEditor || templateWixEditor) && name === 'terms' && (
        <TermsReviewMessage proposalId={prop?._id} />
      )}

      <Row
        className="simple-section-container"
        style={{
          ...simpleSectionLayout.sectionContainerStyle,
          ...(screenWidth < 768 && { flexFlow: 'column' }),
        }}>
        <Col
          span={simpleSectionLayout.simpleSectionTitleSpan}
          className="simple-section-title-block"
          style={simpleSectionLayout.headerStyle}>
          <div className="simple-section-title" style={helpers.getStyleObj(prop, 'title3')}>
            {renderSectionTitle()}
          </div>
          <div className="simple-section-sub-title" style={helpers.getStyleObj(prop, 'title4')}>
            {renderSectionSubTitle()}
          </div>
        </Col>
        {renderMainSection()}
      </Row>

      {
        // prevent section drop on top on section
        sectionDroppable && <div className="prevent-drop-mask" />
      }
    </div>
  );
};

SimpleSection.defaultProps = {
  element: '',
  zoomValue: 100,
  updateLoaderState: () => {},
  // shouldUndoRedoUpdate: false,
  dropDisabled: '',
  askAI: false,
  setAskAI: () => {},
};

SimpleSection.propTypes = {
  name: PropTypes.string.isRequired,
  prop: PropTypes.instanceOf(Object).isRequired,
  config: PropTypes.instanceOf(Object).isRequired,
  element: PropTypes.string,
  setElement: PropTypes.func.isRequired,
  saveProposalDraft: PropTypes.func.isRequired,
  zoomValue: PropTypes.number,
  updateLoaderState: PropTypes.func,
  // shouldUndoRedoUpdate: PropTypes.bool,
  setDisablePreview: PropTypes.func.isRequired,
  dropDisabled: PropTypes.string,
  setDropDisabled: PropTypes.func.isRequired,
  sectionTitlePosition: PropTypes.string,
  sectionDroppable: PropTypes.bool,
  setLastHoveredSection: PropTypes.func,
  askAI: PropTypes.bool,
  setAskAI: PropTypes.func,
  draggingElement: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default SimpleSection;
