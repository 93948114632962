import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M1.65 3H22.35C22.9851 3 23.5 3.44772 23.5 4V20C23.5 20.5523 22.9851 21 22.35 21H1.65C1.01488 21 0.5 20.5523 0.5 20V4C0.5 3.44772 1.01488 3 1.65 3ZM21.2 7.23792L12.0826 14.338L2.8 7.21594V19H21.2V7.23792ZM3.38818 5L12.0712 11.662L20.6261 5H3.38818Z"
      fill="#F03F3B"
    />
  </svg>
);

const EmailOutlineIcon = (props) => <Icon component={svg} {...props} />;

export default EmailOutlineIcon;
