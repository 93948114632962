/* eslint-disable */
import React, { useEffect, useReducer, useImperativeHandle, useState } from 'react';
import { Col } from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Input from 'components/Input';
import { sanitizeString } from 'utils/xss';

const initialState = {
  name: '',
  desc: '',
  goals: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setName':
      return { ...state, name: action.name };
    case 'setDesc':
      return { ...state, desc: action.desc };
    case 'setGoals':
      return { ...state, goals: action.goals };
    default:
      throw new Error('Unexpected action');
  }
};

const ProjectInformation = React.forwardRef(({ prop, save }, ref) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {}, []);

  useEffect(() => {
    if (!loaded) {
      // prevent showing red border in input for first few ms
      setTimeout(() => setLoaded(true), 500);
    }
    dispatch({ type: 'setName', name: prop?.project.name });
    dispatch({ type: 'setDesc', desc: prop?.project.desc });
    dispatch({ type: 'setGoals', goals: prop?.project.goals });
  }, [prop?.project]);

  const onChange = ({ action, type, value }) => {
    value = sanitizeString(value);
    dispatch({ type, [action]: value });
    save({ project: _.extend(prop.project || {}, { [action]: value }) }, true, false, () => {}, 1);
  };

  useImperativeHandle(
    ref,
    () => ({
      resetChosen: () => {
        dispatch({ type: 'setName', name: '' });
        dispatch({ type: 'setDesc', desc: '' });
        dispatch({ type: 'setGoals', goals: '' });

        // save data
        save(
          {
            project: {
              ...prop?.project,
              ...initialState,
            },
          },
          true
        );
      },
    }),
    [dispatch]
  );

  return (
    <Col className="wizard-inner-content">
      <Input
        status={!loaded || state.name ? '' : 'error'}
        label={
          <>
            PROJECT NAME <span className="asterisk">*</span>
          </>
        }
        placeholder="PROJECT NAME"
        defaultValue="The New Video Project"
        value={state.name || ''}
        onChange={(e) =>
          onChange({
            action: 'name',
            type: 'setName',
            value: !!e.target.value.trim() ? e.target.value : '',
          })
        }
      />
      <Input
        status={!loaded || state.desc ? '' : 'error'}
        type="textarea"
        label={
          <>
            PROJECT DESCRIPTION <span className="asterisk">*</span>
          </>
        }
        placeholder="PROJECT DESCRIPTION"
        sublabel="What are you being tasked to do?"
        className="project-description"
        defaultValue="I will transform The Company’s vision into an engaging video that will entertain, educate and make your customers fall in love with your company. The video will include a 2 min long explainer video for investors presentation, and also a 60 / 30 seconds edits for your website and social media."
        value={state.desc || ''}
        onChange={(e) => onChange({ action: 'desc', type: 'setDesc', value: e.target.value })}
      />
      <Input
        type="textarea"
        label="PROJECT GOALS"
        placeholder="PROJECT GOALS"
        sublabel="Why is this project being initiated?"
        className="project-goals"
        defaultValue="The goal of this project is to create an asset that will allow the company to communicate it’s value proposition in a clear entertaining manner in order to facilitate signup growth."
        value={state.goals || ''}
        onChange={(e) =>
          onChange({
            action: 'goals',
            type: 'setGoals',
            value: e.target.value,
          })
        }
      />
    </Col>
  );
});

ProjectInformation.defaultProps = {};

ProjectInformation.propTypes = {
  prop: PropTypes.instanceOf(Object).isRequired,
  save: PropTypes.func.isRequired,
};

export default ProjectInformation;
