/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-destructuring */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { Empty } from 'antd';
import DoubleArrowIcon from 'components/Icons/DoubleArrowIcon';
import CloseIcon from 'components/Icons/CloseIcon';
import { FETCH_PROPOSAL_BACKUPS } from 'graphql/queries/proposalBackups';
import AllVersionsSkeleton from './AllVersionsSkeleton';
import VersionGroup from './VersionGroup';
import { userVar } from 'graphql/cache';

import './AllVersions.scss';

const AllVersionsSidebar = ({
  proposal,
  backupPreviewId,
  setBackupPreviewId,
  backupPreviewLoading,
  restoring,
}) => {
  const user = useReactiveVar(userVar);
  const proposalId = proposal?._id;
  const proposalEdited = new Date(proposal?.edited || Date.now()).getTime();

  const [collapse, setCollapse] = useState(false);
  const [backups, setBackups] = useState([]);
  const [group, setGroup] = useState([]);
  const [liveEditedObj, setLiveEditedObj] = useState({
    _id: 'live',
    editorName: '',
    time: '',
    backupTime: '',
    backupTimestamp: null,
  });

  // load all backup list
  const { loading, refetch: refetchBackups } = useQuery(FETCH_PROPOSAL_BACKUPS, {
    variables: { proposalId },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ fetchProposalBackups }) => {
      if (fetchProposalBackups.length) {
        // segregate the list into group by date
        setBackupPreviewId('live');
        setBackups(fetchProposalBackups);
      }
    },
  });

  // Function to group by date
  const groupByDate = (items) => {
    if (!items) {
      return [];
    }

    const currentData = formatDate(proposalEdited);

    let groups = items.reduce(
      (acc, item) => {
        const date = formatDate(item.backupTime);
        const group = acc.find((g) => g.date === date);

        const _item = {
          _id: item._id,
          editorName: item.editorName,
          backupTime: item.backupTime,
          backupTimestamp: new Date(item.backupTime).getTime(),
          time: timeConverter(item.backupTime),
        };

        //  proposalEdited and backup time is same then hide backup
        if (proposalEdited === _item.backupTimestamp) {
          return acc;
        }

        if (group) {
          group.list.push(_item);
        } else {
          acc.push({ date, list: [_item] });
        }
        return acc;
      },
      [{ date: currentData, list: [] }]
    );

    const firstItem = groups?.[0]?.list?.length ? groups[0].list[0] : null;

    if (groups.length > 0) {
      let time = 'Latest';

      // if for some reason proposal.edited timestamp is older then show newest time in live
      if (firstItem && proposalEdited < firstItem.backupTimestamp) {
        time = firstItem.time;
      } else {
        time = timeConverter(proposalEdited);
      }

      groups[0].list = [
        { ...liveEditedObj }, // add live proposal to top
        ...groups[0].list,
      ];

      setLiveEditedObj((prev) => ({ ...prev, time, editorName: getEditorName(user) }));
    }

    return groups;
  };

  const getEditorName = (user) => {
    if (!proposal?.isEditing) return 'You';
    return user?._id === proposal.isEditing?.uid ? 'You' : proposal.isEditing?.name || '';
  };

  // format date (Today, or exact date (12 March))
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const today = new Date();

    if (date.toDateString() === today.toDateString()) {
      return 'Today';
    } else {
      return date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
    }
  };

  // convert time into HH:MM AA
  const timeConverter = (time) => {
    return new Date(time).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  useEffect(() => {
    setBackupPreviewId('live');
    setGroup([]);
  }, []);

  useEffect(() => {
    if (user) {
      setLiveEditedObj((prev) => ({ ...prev, editorName: getEditorName(user) }));
    }
  }, [user]);

  useEffect(() => {
    if (backups.length) {
      setGroup(groupByDate(backups));
    }
  }, [backups]);

  useEffect(() => {
    if (!proposalEdited || !liveEditedObj) {
      return;
    }
    setGroup(groupByDate(backups));

    // update timestamp for live once old proposal is restored
    setLiveEditedObj((prev) => ({
      ...prev,
      time: timeConverter(proposalEdited),
      backupTimestamp: proposalEdited,
    }));
  }, [proposalEdited]);

  // restored
  useEffect(() => {
    if (restoring?.state === 'restored') refetchBackups({ proposalId });
  }, [restoring]);

  return (
    <div className={`version-sidebar ${collapse ? '' : 'active'}`}>
      <div className="sidebar-opener hide-desktop" onClick={() => setCollapse(false)}>
        <DoubleArrowIcon />
      </div>

      <div className="version-container">
        <div className="version-header">
          <div>
            <h1>Version History</h1>
            <div className="subtitle">Automatically created every 10 mins when edits are made</div>
          </div>
          <CloseIcon className="hide-desktop" onClick={() => setCollapse(true)} />
        </div>

        <div className="lists">
          {loading && <AllVersionsSkeleton />}
          {!loading && group.length > 0 ? (
            group.map((g, groupIndex) => (
              <VersionGroup
                key={`group-${g.date}`}
                {...g}
                groupIndex={groupIndex}
                liveEditedObj={liveEditedObj}
                backupPreviewId={backupPreviewId}
                setBackupPreviewId={setBackupPreviewId}
                backupPreviewLoading={backupPreviewLoading}
                proposalEdited={proposalEdited}
              />
            ))
          ) : (
            <Empty />
          )}
        </div>
      </div>
    </div>
  );
};

AllVersionsSidebar.defaultProps = {
  proposal: {},
  backupPreviewId: null,
  setBackupPreviewId: () => {},
  backupPreviewLoading: false,
};

AllVersionsSidebar.propTypes = {
  proposal: PropTypes.object,
  backupPreviewId: PropTypes.string,
  setBackupPreviewId: PropTypes.func,
  backupPreviewLoading: PropTypes.bool,
};

export default AllVersionsSidebar;
