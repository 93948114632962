import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Row, Col, Dropdown, Menu, Button, Tooltip, Cascader } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { getUserId } from 'graphql/cache';
import { FETCH_WIX_CONTACTS } from 'graphql/queries/wixQueries';
import images from 'constants/images';
import AddRoundIcon from 'components/Icons/AddRoundIcon';
import AddUser from 'components/Icons/AddUser';
import InfoIcon from 'components/Icons/InfoIcon';
import useOutsideClick from 'hooks/useOutsideClick';

import './WixClientName.scss';

const WixClientName = ({
  label,
  type,
  action,
  onChange,
  clientNameValue,
  dupClientErr,
  updateProposal,
  language,
  currency,
  setWixSiteProperties,
  fromTemplate,
}) => {
  const wixClientNameRef = useRef(null);
  const wixContactsObserver = useRef();

  const [clientName, setClientName] = useState(clientNameValue);
  const [clientContacts, setClientContacts] = useState([]);
  const [visibleWixClientName, setVisibleWixClientName] = useState(false);
  const [wixContactsOffset, setWixContactsOffset] = useState(0);

  const handleWixClickCondition = (event) =>
    !event?.target?.className?.match(/ant-select-selection-search-input/i) &&
    !event?.target?.className?.match(/ant-select-item-option-content/i) &&
    !event?.target?.className?.match(/wix-client-name/i) &&
    !event?.target?.className?.match(/wix-client-email/i) &&
    !event?.target?.className?.match(/ant-cascader-input/i) &&
    !event?.target?.className?.match(/ant-cascader-menu-item/i) &&
    !event?.target?.className?.match(/wix-ant-cascader-menu-item/i) &&
    !event?.target?.className?.match(/wix-ant-cascader-menu-item-value/i);

  useOutsideClick(
    wixClientNameRef,
    () => {
      setVisibleWixClientName(false);
    },
    handleWixClickCondition
  );

  const [fetchWixContacts, { loading: isFetchingWixContacts, fetchMore }] = useLazyQuery(
    FETCH_WIX_CONTACTS,
    {
      skip: !getUserId() || clientContacts?.length,
      fetchPolicy: 'network-only',
      onCompleted: ({ fetchWixContacts }) => setClientContacts(fetchWixContacts),
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const fetchMoreContacts = async () => {
    const refetchContactsData = await fetchMore({
      variables: {
        offset: wixContactsOffset,
      },
    });
    const fetchedContacts = refetchContactsData?.data?.fetchWixContacts;
    const loadedcontacts = clientContacts;

    const tempClientsContacts = loadedcontacts.map((clientContact, clientContactIndex) => {
      clientContact = Object.assign({}, clientContact, {
        contacts: [...clientContact.contacts, ...fetchedContacts[clientContactIndex].contacts],
      });
      clientContact = Object.assign({}, clientContact, {
        hasNext: fetchedContacts[clientContactIndex].hasNext,
      });
      return clientContact;
    });
    setClientContacts(tempClientsContacts);
  };

  useEffect(() => {
    fetchWixContacts({
      variables: {
        offset: 0,
      },
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    if (wixContactsOffset) fetchMoreContacts();
  }, [wixContactsOffset]); // eslint-disable-line

  const lastBookElementRef = useCallback(
    (node) => {
      if (isFetchingWixContacts) return;
      if (wixContactsObserver.current) wixContactsObserver.current.disconnect();
      wixContactsObserver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setWixContactsOffset((prev) => prev + 1);
        }
      });
      if (node) wixContactsObserver.current.observe(node);
    },
    [isFetchingWixContacts]
  );

  const bubbleChange = _.debounce(({ value, email, instanceId }) => {
    if (fromTemplate) {
      onChange(value);
    } else {
      onChange({ type, action, value });
      let updateProposalParams = {};

      if (instanceId) {
        const selectedContact = clientContacts?.find(
          (wixContact) => wixContact?.instanceId === instanceId
        );

        if (email) {
          onChange({
            type: 'setEmail',
            action: 'email',
            value: email,
          });
          updateProposalParams = { email, instanceId };
        }

        if (selectedContact) {
          updateProposalParams = {
            ...updateProposalParams,
            currency: selectedContact.currency || currency,
          };
          setWixSiteProperties(
            {
              name: selectedContact.name,
              description: selectedContact.description,
              logo: selectedContact.logo,
              domian: selectedContact.domian,
              currency: selectedContact.currency,
            } || {}
          );
        }
      } else {
        updateProposalParams = { instanceId };
      }

      updateProposal(updateProposalParams);
    }
  }, 500);

  const onInputChange = (value, email, instanceId) => {
    setClientName(value);
    bubbleChange({ value, email, instanceId });
  };

  const menu = (
    <Menu
      className={`wix-client-name-dropdown-menu ${
        language === 'hebrew' || language === 'arabic' ? 'language-rtl' : ''
      }`}>
      <Menu.Item>
        <Cascader
          allowClear={false}
          placement="topRight"
          defaultValue={[clientName]}
          value={[clientName]}
          open={true}
          options={clientContacts?.map((clientContact) => ({
            value: clientContact.instanceId,
            label: `${clientContact.name} ${
              clientContact.domian ? '(' + clientContact.domian + ')' : ''
            }`,
            disabled: false,
            children: [
              ...clientContact.contacts.map((contact, index) => ({
                value: contact.value + index,
                label: (
                  <span
                    className="wix-ant-cascader-menu-item"
                    onClick={() =>
                      onInputChange(
                        !!contact.value?.trim() ? contact.value : '',
                        contact.email,
                        clientContact.instanceId
                      )
                    }>
                    <span className="wix-ant-cascader-menu-item-value"> {contact.value}</span>
                    <span className="wix-ant-cascader-menu-item-value">
                      {contact.email ? `(${contact.email})` : ''}
                    </span>
                  </span>
                ),
              })),
              clientContact.hasNext
                ? {
                    children: [],
                    label: (
                      <span className="wix-ant-cascader-menu-item">
                        <div ref={lastBookElementRef} />
                        <span className="wix-ant-cascader-menu-item-load-more">Loading...</span>
                      </span>
                    ),
                  }
                : {},
            ],
          }))}
          getPopupContainer={(trigger) => trigger.parentElement}
          suffixIcon={<AddUser />}
          placeholder="New Contact"
          expandTrigger="hover"
          showSearch={{
            filter: (inputValue, path) =>
              path.some(
                (option) => option?.value?.toLowerCase().indexOf(inputValue?.toLowerCase()) > -1
              ),
            matchInputWidth: true,
            sort: (a, b, inputValue, names) => {
              const callback = (elem) => elem[names?.value]?.indexOf(inputValue) > -1;
              return a?.findIndex(callback) - b?.findIndex(callback);
            },
            render: (_, path) => {
              const name = path?.[1]?.value;
              const label = path?.[0]?.label;
              const instanceId = path?.[0]?.value;
              const email = path?.[1]?.label?.props?.children[1]?.props?.children
                ? path?.[1]?.label?.props?.children[1]?.props?.children
                : '';
              return (
                <span
                  class="wix-ant-cascader-menu-item"
                  onClick={() => onInputChange(!!name?.trim() ? name : '', email, instanceId)}>
                  {label} / {name} {email}
                </span>
              );
            },
          }}
          notFoundContent="No contact found"
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              setVisibleWixClientName(false);
              onInputChange(!!event.target.value?.trim() ? event.target.value : '');
            }
          }}
        />
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="wix-client-name-dropdown-container" ref={wixClientNameRef}>
      {label && (
        <Row>
          <label className="input-label">
            {label}
            <span className="asterisk">*</span>
          </label>
          <Tooltip
            overlayClassName="wizard-tooltip-email"
            title="Search for a Wix contact or add a new one that will be available on Prospero only."
            color="black"
            key="white">
            <InfoIcon />
          </Tooltip>
        </Row>
      )}

      <Dropdown overlay={menu} trigger={['click']} visible={visibleWixClientName}>
        <Button
          id="wix-client-name-dropdown-button"
          onClick={() => setVisibleWixClientName(!visibleWixClientName)}>
          <Row justify="space-between">
            <Col>
              <img alt="Wix" className={fromTemplate ? 'wix-template' : ''} src={images.WIX_ICON} />
              <span className={`wix-button-text ${fromTemplate ? 'wix-template' : ''}`}>
                {clientName || 'Select/Add Contact (↵ to add)'}
              </span>
            </Col>
            <Col>
              <AddRoundIcon />
            </Col>
          </Row>
        </Button>
      </Dropdown>
      <p>{dupClientErr}</p>
    </div>
  );
};

WixClientName.defaultProps = {
  dupClientErr: '',
  clientNameValue: '',
};

WixClientName.propTypes = {
  type: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  clientNameValue: PropTypes.string,
  dupClientErr: PropTypes.string,
  setWixSiteProperties: PropTypes.func.isRequired,
  language: PropTypes.string,
};

export default WixClientName;
