import React from 'react';
import { Col, Row, Slider, Radio, Form, Input, Select, message } from 'antd';
import moment from 'moment';
import { CaretDownOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { DELETE_TEAM_MEMBER } from 'graphql/mutations/usersTeamMutation';
import { useMutation } from '@apollo/client';

import DeleteIcon from 'components/Icons/DeleteIcon';
import AddTrialUsers from 'components/Icons/AddTrialUsers';

const daystrial = parseInt(process.env.REACT_APP_DAYS_TRAIL) || 14;

const maxUsersInvitation = parseInt(process.env.REACT_APP_MAX_USER_INVITE) || 25;

const jobs = [
  { text: 'Manager', value: 'manager' },
  { text: 'Editor', value: 'editor' },
];

const { Option } = Select;

const Pricing = ({
  formFields,
  user,
  usersTeam,
  setUsersTeam,
  changeUserTeamCount,
  newUsersCount,
  addRemoveRef,
  usersToBeDeleted,
  setUsersToBeDeleted,
  plan,
  setPlan,
  setNewUsersCount,
}) => {
  const [removeOneTeamMember] = useMutation(DELETE_TEAM_MEMBER, {
    onCompleted: ({ removeOneTeamMember }) => {
      if (removeOneTeamMember) {
        let updatedUsersTeam = usersTeam.filter((team) => {
          return team._id !== usersToBeDeleted._id;
        });
        if (updatedUsersTeam) {
          message.success('User removed successfully');
        }
      }
    },
    onError: (error) => {
      console.log('error::::', error);
    },
  });

  const doRemoveUserTeam = (_id) => {
    if (usersTeam.length > 0) {
      removeOneTeamMember({ variables: { uid: _id } });
      let newUserTeam = usersTeam.filter((user) => {
        return user._id !== _id;
      });
      usersTeam = newUserTeam;
    }
  };

  const { isLegacy } = user?.paymentStatus;

  const getTrialTime = () => {
    if (!user) {
      return 666;
    }

    const timespan = new Date() - new Date(user.stopperAnchor || user.createdAt);
    const dayspan = timespan / 1000 / 60 / 60 / 24;

    return daystrial - dayspan;
  };

  const trialTime = getTrialTime();
  const trial = trialTime >= 0 ? true : false;

  let { nextPaymentDate } = user?.paymentStatus;
  let renewaldate = nextPaymentDate && moment(nextPaymentDate).format('MMM Do, YYYY');

  if (isLegacy) {
    nextPaymentDate = null;
    renewaldate = null;
  } else if (!renewaldate && trial) {
    renewaldate = moment().add(trialTime, 'days').format('MMM Do, YYYY');
  }

  const userCount = usersTeam.length + newUsersCount + 1;
  const monthlyRate = 19;
  const yearlyDiscountedRate = 12;
  const originalAnnualPrice = monthlyRate * userCount;
  const yearlyTotalAmount = yearlyDiscountedRate * userCount * 12;

  return (
    <div className="pricing plans">
      <Col md={17} xs={18} className="plan-type-toggler">
        <div className="toggle-switches">
          <Radio.Group
            value={plan}
            onChange={(e) => {
              const planType = e.target.value;
              setPlan(planType);
            }}>
            <Radio.Button value="monthly" checked={plan === 'monthly' ? true : false}>
              <span className="switch-toggle-button-text"> Monthly </span>
            </Radio.Button>
            <Radio.Button value="yearly" checked={plan === 'yearly' ? true : false}>
              <span className="switch-toggle-button-text"> Annually (🔥 Save 37%)</span>
            </Radio.Button>
          </Radio.Group>
        </div>
      </Col>
      <Col className="plans-content">
        <Row className="plans-info">
          <Col md={15} xs={12}>
            <Row className="plans-payment-buttons trial">
              <div className="trial-user-select-plan">
                <p>
                  <AddTrialUsers /> How many members are in your team?
                </p>
                <p className="trial-plan-count">{usersTeam.length + newUsersCount + 1}</p>
                <Slider
                  defaultValue={10}
                  tooltipVisible={false}
                  value={usersTeam?.length + newUsersCount + 1}
                  min={usersTeam.length > 0 ? usersTeam.length + 1 : 1}
                  max={maxUsersInvitation}
                  onChange={changeUserTeamCount}
                />
              </div>
            </Row>
          </Col>
          <Col md={8} xs={11}>
            <Row className="plans">
              <div className="price">
                <small className="money-sign">$</small>
                {plan === 'monthly' ? monthlyRate * userCount : yearlyDiscountedRate * userCount}
              </div>
              <div className="discount-tenure">
                <p className="discount">
                  {plan === 'yearly' ? <s>${originalAnnualPrice}</s> : <>&nbsp;</>}
                </p>
                <span className={`tenure ${plan === 'monthly' ? 'bottom-aligned' : ''}`}>
                  /month
                </span>
                {plan === 'yearly' && <p className="tenure-length">x 12</p>}
              </div>
              {plan !== 'monthly' && <span>(${yearlyTotalAmount} billed annually)</span>}
            </Row>
          </Col>
          <Col span={24}>
            <div
              className={`form-container ${
                usersTeam.length > 0 || newUsersCount > 0 ? 'visible' : ''
              }`}>
              <div className="form-wraper">
                <Form.List name="users" autoComplete="off">
                  {(fields, { add, remove }) => {
                    addRemoveRef.current = { add, remove };
                    return (
                      <>
                        {fields.length > 2 && <div className="scroll-shadow" />}
                        {fields.map((field, index) => (
                          <div key={`AddUser${index}`} className="user-group-container">
                            <div className="user-group">
                              <div
                                className={`user-info-container ${
                                  usersTeam[field.key]?.emails[0]?.verified === false
                                    ? 'user-info-container-unverified'
                                    : ''
                                }`}
                                key={`user-info-container-${index}`}>
                                <Form.Item
                                  label="Email"
                                  className="user-group-item user-group-item-email"
                                  name={[field.name, 'email']}
                                  rules={[
                                    {
                                      required: true,
                                      type: 'email',
                                      message: 'Please enter an email',
                                    },
                                  ]}>
                                  <Input
                                    placeholder="Email Address"
                                    disabled={index >= usersTeam.length ? false : true}
                                  />
                                </Form.Item>
                                <Form.Item
                                  label="Role"
                                  className="user-group-item user-group-item-role"
                                  name={[field.name, 'role']}
                                  initialValue="manager"
                                  rules={[{ required: true, message: 'Role required' }]}>
                                  <Select
                                    suffixIcon={<CaretDownOutlined color="red" />}
                                    disabled={index >= usersTeam.length ? false : true}>
                                    {jobs.map((job) => (
                                      <Option key={job.value} value={job.value}>
                                        {job.text}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                                <DeleteIcon
                                  className="remove-icon"
                                  onClick={() => {
                                    if (index < usersTeam.length) {
                                      const member = usersTeam[index];
                                      const tempUsersToBeDeleted = [
                                        ...(usersToBeDeleted || []),
                                        {
                                          _id: member._id,
                                          email: member.emails[0].address,
                                          role: member.roles[0],
                                        },
                                      ];
                                      setUsersToBeDeleted(tempUsersToBeDeleted);
                                      doRemoveUserTeam(member._id);
                                      setUsersTeam((prev) => prev.filter((_, i) => i !== index));
                                    } else {
                                      setNewUsersCount((prev) => prev - 1);
                                    }
                                    remove(field.name);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    );
                  }}
                </Form.List>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

Pricing.defaultProps = {
  user: {},
};

Pricing.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  setPlan: PropTypes.func,
  usersTeam: PropTypes.instanceOf(Array),
  setUsersTeam: PropTypes.func,
  changeUserTeamCount: PropTypes.func,
  newUsersCount: PropTypes.number,
  addRemoveRef: PropTypes.instanceOf(Object),
  usersToBeDeleted: PropTypes.instanceOf(Array),
  setUsersToBeDeleted: PropTypes.func,
  plan: PropTypes.string,
  formFields: PropTypes.instanceOf(Object),
};

export default Pricing;
