import React, { useRef, useEffect, useReducer, useState, useContext } from 'react';
import { Slider, Progress, Spin, message, Menu } from 'antd';
import { useReactiveVar } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { stateFromHTML } from 'draft-js-import-html';

import PropTypes from 'prop-types';
import Immutable from 'immutable';
import _ from 'lodash';
import getFragmentFromSelection from 'draft-js/lib/getFragmentFromSelection';

import {
  Editor,
  EditorState,
  DefaultDraftBlockRenderMap,
  convertFromRaw,
  convertToRaw,
  RichUtils,
  Modifier,
  AtomicBlockUtils,
  SelectionState,
  ContentState,
  getDefaultKeyBinding,
  ContentBlock,
  genKey,
  convertFromHTML,
  CompositeDecorator,
} from 'draft-js';

import {
  reducer,
  initialState,
  blockStyler,
  editorChange,
  blockRenderer,
  createCellBlock,
} from './lib';
import { ToolBar, AIToolBar } from './components';
import { userVar, fontLoadedVar, featureFlagsVar } from 'graphql/cache';
import { resizeImageForS3 } from 'utils/uploadToS3';
import {
  fontWeights,
  fontSizes,
  defaultFormFields,
  fontListEnglish,
  fontListHebrew,
} from 'pages/Proposal/constants/constants';
import { ELEMENTS_VARIABLES, testimonial } from 'constants/index';

import Uploader from 'components/Uploader';
import FileCopier from 'components/FileCopier';
import ChevronIcon from 'components/Icons/ChevronIcon';
import useSuspiciousLink from 'hooks/useSuspiciousLink';
import commonHelper from 'helpers';

import RichEditorModals from './RichEditorModals/RichEditorModals';
import proposalUtils from '../Draft/utils';
import utils from './utils';
import DraftContext from 'context/DraftContext';
import commonUtils from 'utils/utils';
import HyperLinkModals from './components/HyperLinkSection/HyperLinkModals';
import RenderColorPicker from './components/ColorPicker';
import Link from './components/Link/Link';

import helpers, { loadProposalFont, scrollToSection } from 'helpers/proposal';
import { HyperLinkSection } from './components/HyperLinkSection/HyperLinkSection';
import { updateSelectionInlineToolbar, getBlockSelection } from './lib/selection';

import './RichEditor.scss';
import { useProposalContext } from 'context/proposal';

const { Map } = Immutable;
const { SubMenu } = Menu;
let fontLoaded = [];

const RichEditor = (props) => {
  const { clickCounterRef } = useContext(DraftContext);
  const {
    id,
    raw,
    prop,
    config,
    configText,
    element,
    setElement,
    elementValue,
    blockRenderMap,
    content,
    htmlOptions,
    simple,
    placeholder,
    contentType,
    saveProposal,
    saveUser,
    zoomValue,
    sectionName,
    setSelectedBlock,
    updateLoaderState,
    setOpenVariablesList,
    setScrollingTo,
    sectionType,
    saveProposalDraft,
    setDisablePreview,
    dropDisabled,
    setDraggingElement,
    numberOfColumns,
    columnIndex,
    sectionSpan,
    titleSpan,
    setElementsInSection,
    wixEditor,
    activeEditorId,
    setActiveEditorId,
    templateWixEditor,
    askAI,
    setAskAI,
    type,
  } = props;
  const user = useReactiveVar(userVar);
  const featureFlag = useReactiveVar(featureFlagsVar);
  const { checkSuspiciousUrl } = useSuspiciousLink({ proposalId: prop._id });
  const { isEditingModal } = useProposalContext();

  const [state, dispatch] = useReducer(reducer, initialState);
  const isRtl = ['hebrew', 'arabic'].includes(prop?.language?.toLowerCase());

  function findLinkEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      if (entityKey) {
        const entity = contentState.getEntity(entityKey);
        const { type } = entity;
        return type === 'LINK';
      }
    }, callback);
  }

  findLinkEntities.defaultProps = {
    contentBlock: '',
    callback: () => {},
  };

  findLinkEntities.propTypes = {
    contentBlock: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
    callback: PropTypes.func,
  };

  const BRACKETS_REGEX = /{{((?:(?=([^{}]+|{{[^}]*}}))\2)*)}}/gi;

  const findWithRegex = (regex, contentBlock, callback) => {
    const text = contentBlock.getText();
    let matchArr, start;
    // eslint-disable-next-line
    while ((matchArr = regex.exec(text)) !== null) {
      start = matchArr.index;
      callback(start, start + matchArr[0].length);
    }
  };

  function findVariableEntities(contentBlock, callback, contentState) {
    findWithRegex(BRACKETS_REGEX, contentBlock, callback);
  }

  findVariableEntities.defaultProps = {
    contentBlock: '',
    callback: () => {},
  };

  findVariableEntities.propTypes = {
    contentBlock: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
    callback: PropTypes.func,
  };

  const Variable = (props) => {
    return (
      <span {...props} style={{ color: '#f03f3b', fontSize: '80%' }}>
        {props.children}
      </span>
    );
  };

  Variable.defaultProps = {
    entityKey: '',
  };

  Variable.propTypes = {
    entityKey: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
  };

  // ------------ render highligh in a span with 'highlight' class ---------------
  const findHighlightEntities = (contentBlock, callback) => {
    contentBlock.findStyleRanges((character) => {
      return character.hasStyle('HIGHLIGHT');
    }, callback);
  };

  const HighlightSpan = (props) => {
    return <span className="highlight">{props.children}</span>;
  };
  // ------------ render highlight ---------------

  // ------------ render highligh ai in a span with 'highlight-ai' class ---------------
  const findHighlightAIEntities = (contentBlock, callback) => {
    contentBlock.findStyleRanges((character) => {
      return character.hasStyle('HIGHLIGHT-AI');
    }, callback);
  };

  const HighlightAISpan = (props) => {
    return <span className="highlight-ai">{props.children}</span>;
  };
  // ------------ render highlight ai ---------------

  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: (props) => <Link {...props} dispatch={dispatch} promptForLink={promptForLink} />,
    },
    {
      strategy: findVariableEntities,
      component: Variable,
    },
    {
      strategy: findHighlightEntities,
      component: HighlightSpan,
    },
    {
      strategy: findHighlightAIEntities,
      component: HighlightAISpan,
    },
  ]);

  const editor = useRef(null);
  const imageInputRef = useRef();
  const richEditorRef = useRef();
  const editorRef = useRef();
  const displayAIToolBar = useRef();
  const displayAIToolBarCursor = useRef();
  const dragStartRef = useRef();
  const aiSaving = useRef(false);

  const [upload, uploadStatus] = Uploader();
  const [showImageProgress, toggleImageProgress] = useState(false);
  const [imageName, setImageName] = useState('');
  const [readOnly, setReadOnly] = useState(false);
  const [toolbarPosition, setToolbarPosition] = useState({});
  const [shouldCheckUrl, setShouldCheckUrl] = useState(null);
  const [, setDeletedBlockKeys] = useState([]);

  const { selectedBlock } = state;
  const [copy, copyStatus] = FileCopier();
  const [bodyFont, setBodyFont] = useState(null); // store draft.bodyFont

  const [isValidUrl, setIsValidUrl] = useState(false);

  const focusEditor = () => {
    if (dropDisabled) {
      setReadOnly(false);
    }

    editor?.current?.focus();
    dispatch({ type: 'setEditorReadOnly', editorReadOnly: false });
  };

  useEffect(
    () => setSelectedBlock && setSelectedBlock(selectedBlock),
    [selectedBlock, setSelectedBlock]
  );

  useEffect(() => {
    // for simple section only
    if (simple === 0 && !_.isEqual(prop?.draft?.bodyFont, bodyFont)) {
      setBodyFont(prop.draft.bodyFont);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prop?.draft?.bodyFont]);

  useEffect(() => {
    if (bodyFont) {
      editorRef.current = state?.editorState;
      setTimeout(focusEditor, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bodyFont]);

  const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

  useEffect(() => {
    if (shouldCheckUrl) {
      checkSuspiciousUrl(shouldCheckUrl);
      setShouldCheckUrl(null);
    }

    const currentSelection = state?.editorState ? state?.editorState?.getSelection() : null;
    const currentRaw = editorRef?.current?.getCurrentContent()
      ? convertToRaw(editorRef?.current?.getCurrentContent())
      : '';

    if (
      ((currentSelection && currentSelection?.getHasFocus() && state.keyType) ||
        !currentSelection?.getHasFocus() ||
        (currentSelection?.getHasFocus() && raw && !_.isEqual(raw, currentRaw))) &&
      !displayAIToolBar.current &&
      raw &&
      !_.isEqual(raw, currentRaw)
    ) {
      let tempEditorState = EditorState.createWithContent(
        convertFromRaw(raw, extendedBlockRenderMap),
        decorator
      );

      tempEditorState = EditorState.acceptSelection(tempEditorState, currentSelection);
      editorRef.current = tempEditorState;
      dispatch({ type: 'setEditorState', editorState: tempEditorState });
      dispatch({ type: 'setKeyType', keyType: '' });
    } else if (!editorRef.current) {
      let tempEditorState = raw
        ? EditorState.createWithContent(convertFromRaw(raw, extendedBlockRenderMap), decorator)
        : content
        ? EditorState.createWithContent(stateFromHTML(content, htmlOptions || {}), decorator)
        : EditorState.createEmpty();

      tempEditorState = EditorState.set(tempEditorState, { decorator });

      editorRef.current = tempEditorState;
      dispatch({ type: 'setEditorState', editorState: tempEditorState });
      dispatch({
        type: 'setBlockRenderMap',
        blockRenderMap: extendedBlockRenderMap,
      });
      dispatch({ type: 'setSimple', simple: simple || 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [raw]);

  // Add highlight to selected AI text
  const formatBackgroundSelectionColor = (editorState, returnEditorState) => {
    const selectionState = editorState.getSelection();

    if (selectionState && !selectionState?.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const blocks = contentState.getBlockMap();
      let newContentState = contentState;

      blocks.forEach((block) => {
        // remove HIGHLIGHT from the entire block
        newContentState = Modifier.removeInlineStyle(
          newContentState,
          getBlockSelection(block),
          'HIGHLIGHT-AI'
        );
      });

      try {
        // apply highlight only to the selected text
        newContentState = Modifier.applyInlineStyle(
          newContentState,
          selectionState,
          'HIGHLIGHT-AI'
        );
      } catch (e) {
        console.log(e);

        displayAIToolBar.current = '';
        displayAIToolBarCursor.current = '';
      }
      const newEditorState =
        // RichUtils.toggleBlockType(
        EditorState.push(editorState, newContentState, 'change-inline-style');
      // 'HIGHLIGHT-AI'
      // );
      // console.log('Add highlight');

      editorRef.current = newEditorState;
      dispatch({ type: 'setEditorState', editorState: newEditorState });

      if (returnEditorState) {
        return newEditorState;
      }
    }
    // console.log(editorState, 'editorState');

    // let es = RichUtils.toggleInlineStyle(editorState, `bg-rgba(24, 144, 255, 1)`);
    // es = RichUtils.toggleInlineStyle(es, `rgba(255, 255, 255, 1)`);

    // editorRef.current = es;
  };

  // Function to check if the cursor is at the end of a word
  const isCursorAtEndOfWord = (editorState, blockKey) => {
    editorState = editorState || editorRef.current;

    const selection = editorState?.getSelection();
    const anchorKey = selection?.getAnchorKey();
    const currentContent = editorState?.getCurrentContent();
    const currentBlock = currentContent?.getBlockForKey(anchorKey);
    const blockText = currentBlock?.getText();
    const cursorPosition = selection?.getAnchorOffset();
    const focusPosition = selection?.getFocusOffset();

    if (focusPosition !== cursorPosition) {
      return;
    }

    // Check if the cursor is at the end of the block
    if (!cursorPosition && !blockText?.length) {
      return blockKey;
    }

    // Check if the cursor is at the end of the block
    if (cursorPosition === blockText?.length) {
      return cursorPosition === 0 ? '0' : cursorPosition;
    }

    if (
      typeof cursorPosition === 'number' &&
      cursorPosition === 0 &&
      (selection.getHasFocus() || displayAIToolBarCursor.current)
    ) {
      return blockKey;
    }

    // Check if the character at the cursor position is not a word character
    const charAfterCursor = blockText?.[cursorPosition];
    const isEndOfWord = /\s/.test(charAfterCursor);
    // console.log('Cursor position', cursorPosition, focusPosition, selection);

    return isEndOfWord
      ? // && selection.getHasFocus()
        cursorPosition
      : '';
  };

  // Function to check if the cursor is at the end of the block
  const isCursorAtEndOfBlock = () => {
    const editorState = editorRef.current;

    const selectionState = editorState?.getSelection();
    const contentState = editorState?.getCurrentContent();
    const blockKey = selectionState?.getAnchorKey();
    const block = contentState?.getBlockForKey(blockKey);
    const blockLength = block?.getLength();

    // Get the cursor's position in the block
    const selectionOffset = selectionState?.getAnchorOffset();

    // Check if the cursor is at the end
    return selectionOffset === blockLength;
  };

  // useEffect to add/remove AI prompt
  useEffect(() => {
    if (
      prop.language === 'english' &&
      ((!user?.profile?.enableAITool && prop?.enableAITool) ||
        (user?.profile?.enableAITool && prop?.enableAITool)) &&
      editorRef.current &&
      sectionName !== 'header' &&
      sectionName !== 'signature' &&
      !state.displayLink
    ) {
      let selectionNew = editorRef.current?.getSelection();

      const selected = getFragmentFromSelection(editorRef?.current);

      let selectedText = selected ? selected.map((x) => x.getText()).join('\n') : '';

      if (selectedText[selectedText.length - 1] === '.') {
        selectedText = selectedText.slice(0, -1);
      }

      if (!selectedText.trim().length && !selectionNew?.isCollapsed()) {
        const contentState = editorRef.current?.getCurrentContent();

        // Get the anchor and focus keys and offsets
        const anchorKey = selectionNew.getAnchorKey();
        const focusKey = selectionNew.getFocusKey();
        const anchorOffset = selectionNew.getAnchorOffset();
        const focusOffset = selectionNew.getFocusOffset();

        const isBackward = selectionNew.getIsBackward(); // Check if the selection is reversed
        const blockMap = contentState.getBlockMap();

        let hasStarted = false;

        blockMap.forEach((block, key) => {
          const blockText = block.getText();

          if (key === anchorKey || key === focusKey || (hasStarted && key !== focusKey)) {
            if (key === anchorKey || key === focusKey) {
              const startOffset =
                isBackward || block.getType() === 'ordered-list-item' ? focusOffset : anchorOffset;
              const endOffset =
                isBackward || block.getType() === 'ordered-list-item' ? anchorOffset : focusOffset;

              if (key === anchorKey && key === focusKey) {
                // If the selection is within the same block
                selectedText += blockText.slice(
                  Math.min(startOffset, endOffset),
                  Math.max(startOffset, endOffset)
                );
              } else if (key === anchorKey) {
                // Start block
                selectedText += blockText.slice(startOffset) + '\n';
                hasStarted = true;
              } else if (key === focusKey) {
                // End block
                selectedText += blockText.slice(0, endOffset || blockText.length);
                hasStarted = false;
              }
            } else if (hasStarted) {
              // In between blocks
              selectedText += blockText + '\n';
            }
          }
        });

        if (selectedText?.trim()?.length < 2) {
          displayAIToolBar.current = '';
          displayAIToolBarCursor.current = '';
          return null;
        }

        selectionNew = new SelectionState({
          anchorKey: selectionNew.getFocusKey(),
          anchorOffset: selectionNew.getFocusOffset(),
          focusKey: selectionNew.getAnchorKey(),
          focusOffset: selectionNew.getAnchorOffset(),
          hasFocus: true,
        });

        editorRef.current = EditorState.forceSelection(editorRef.current, selectionNew);
        dispatch({
          type: 'setEditorState',
          editorState: EditorState.forceSelection(editorRef.current, selectionNew),
        });
      }

      console.log(
        '3'
        // selectionNew,
        // !selectionNew?.isCollapsed(),
        // !displayAIToolBar.current,
        // sectionName,
        // sectionName !== 'header',
        // sectionName !== 'signature',
        // !state.displayLinkTextInput,
        // !state.displayLinkInput,
        // !state.displayColorPicker,
        // selectedText.trim().length > 0,
        // !_.isEqual(state.aISelectionState, selectionNew)
      );
      if (
        !selectionNew?.isCollapsed() &&
        !displayAIToolBar.current &&
        sectionName &&
        sectionName !== 'header' &&
        sectionName !== 'signature' &&
        !state.displayLinkTextInput &&
        !state.displayLinkInput &&
        !state.displayColorPicker &&
        selectedText?.trim()?.length > 1 &&
        !_.isEqual(state.aISelectionState, selectionNew)
      ) {
        dispatch({ type: 'setAISelectionState', aISelectionState: selectionNew });
        console.log('1');
        formatBackgroundSelectionColor(editorRef.current);
        displayAIToolBar.current = `${sectionName}_${simple}`;
        displayAIToolBarCursor.current = '';
        aiSaving.current = false;
      } else if (
        state.editorState &&
        selectionNew?.isCollapsed() &&
        (displayAIToolBar.current || state.displayLinkInput)
      ) {
        let newEditorState = state.editorState;

        if (state.aISelectionState) {
          newEditorState = EditorState.forceSelection(newEditorState, state.aISelectionState);

          // if (!isCursorAtEndOfBlock()) { // commented for - Double select and click on the end of the line
          newEditorState = _clearFormatting(newEditorState, false);
          // }
        }
        // const emptySelection = new SelectionState({
        //   anchorKey: state.aISelectionState.getFocusKey(),
        //   anchorOffset: state.aISelectionState.getFocusOffset(),
        //   focusKey: state.aISelectionState.getFocusKey(),
        //   focusOffset: state.aISelectionState.getFocusOffset(),
        //   hasFocus: false,
        // });

        console.log('2');

        newEditorState = EditorState.forceSelection(newEditorState, selectionNew);
        editorRef.current = newEditorState;
        dispatch({ type: 'setDisplayColorPicker', displayColorPicker: '' });
        handleClickOutside(newEditorState);
      }

      if (selectionNew?.isCollapsed() && selectionNew.getFocusKey() && !displayAIToolBar.current) {
        const cursorPosition = isCursorAtEndOfWord(null, selectionNew.getFocusKey());
        // console.log(
        //   cursorPosition,
        //   displayAIToolBarCursor.current,
        //   state?.aISelectionState?.getSelection?.()?.getFocusKey(),
        //   'cursorPosition'
        // );

        if (
          (cursorPosition === '0' ||
            (cursorPosition && cursorPosition !== displayAIToolBarCursor.current)) &&
          !state.displayVariableInput
          // &&
          // askAI
        ) {
          console.log('21');

          let newEditorState = editorRef.current; //state.editorState; // comment for title not loading
          if (state.aISelectionState) {
            newEditorState = EditorState.forceSelection(newEditorState, state.aISelectionState);
            newEditorState = _clearFormatting(newEditorState, false);
          }
          newEditorState = EditorState.forceSelection(newEditorState, selectionNew);
          editorRef.current = newEditorState;

          setAskAI(false);
          updateSelectionInlineToolbar(props.id, props.prop.language, dispatch);
          displayAIToolBarCursor.current = cursorPosition;
          aiSaving.current = false;
        } else if (!cursorPosition && displayAIToolBarCursor.current) {
          console.log('22');

          setAskAI(false);
          displayAIToolBarCursor.current = '';
        }
      }

      if (
        (!!selectionNew?.isCollapsed() &&
          !!selectionNew?.getHasFocus() &&
          !!askAI &&
          !!displayAIToolBarCursor.current) ||
        (!!displayAIToolBarCursor.current && selectedText && selectedText.trim().length < 2)
      ) {
        setAskAI(false);
        displayAIToolBarCursor.current = '';
      }
      // To be used if selection dosen't disappears

      // else if (!selectionNew?.isCollapsed() &&
      //   displayAIToolBar.current) {
      //   let newEditorState = state.editorState;
      //   if (state.aISelectionState) {
      //     newEditorState = EditorState.forceSelection(newEditorState, state.aISelectionState);
      //     // newEditorState = clearBackgroundSelectionColor(newEditorState);
      //   }

      //   newEditorState = EditorState.forceSelection(newEditorState, selectionNew);
      //   formatBackgroundSelectionColor(newEditorState);
      //   dispatch({ type: 'setAISelectionState', aISelectionState: selectionNew });

      //   newEditorState = RichUtils.toggleInlineStyle(newEditorState, `bg-rgba(24, 144, 255, 1)`);
      //   newEditorState = RichUtils.toggleInlineStyle(newEditorState, `rgba(255, 255, 255, 1)`);

      //   editorRef.current = newEditorState;
      //   dispatch({ type: 'setDisplayAIToolBar', displayAIToolBar: '' });
      //   dispatch({ type: 'setAskAI', askAI: false });
      //   dispatch({ type: 'setDisplayLinkTextInput', displayLinkTextInput: false });
      //   dispatch({ type: 'setDisplayLinkInput', displayLinkInput: false });
      //   dispatch({ type: 'setDisplayColorPicker', displayColorPicker: '' });
      //   dispatch({ type: 'setAISelectionState', aISelectionState: '' });

      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayAIToolBar.current, displayAIToolBarCursor.current, editorRef.current]);

  const handleClickOutside = (editorState, type, selection) => {
    if (
      prop.language === 'english' &&
      ((!user?.profile?.enableAITool && prop?.enableAITool) ||
        (user?.profile?.enableAITool && prop?.enableAITool))
    ) {
      // const oldSelection = state?.editorState?.getSelection();
      // const emptySelection = SelectionState.createEmpty(oldSelection?.getAnchorKey()).set(
      //   'hasFocus',
      //   type === 'inside' ? true : false
      // );
      // const windowsSelection = window.getSelection();
      // console.log(windowsSelection, windowsSelection.isCollapsed, windowsSelection.anchorOffset === editorRef.current.getSelection().anchorOffset, windowsSelection.focusOffset === editorRef.current.getSelection().focusOffset, '=-windowsSelection');
      // const emptySelection = new SelectionState({
      //   anchorKey: oldSelection.getAnchorKey(),
      //   anchorOffset: windowsSelection.anchorOffset,
      //   focusKey: oldSelection.getFocusKey(),
      //   focusOffset: windowsSelection.focusOffset,
      //   hasFocus: type === 'inside' ? true : false,
      // });
      editorState = editorState || editorRef.current || state.editorState;
      // console.log(editorState, 'editorState');

      // editorState = editorRef.current;
      if (selection) editorState = EditorState.forceSelection(editorState, selection);
      // console.log(emptySelection, oldSelection, editorRef.current?.getSelection(), editorState && (editorState?.getSelection?.()?.isCollapsed()), (editorState?.getSelection().isCollapsed() !== false &&
      //   !editorState?.getSelection()?.getHasFocus()));
      // Update the editorState with the new empty selection
      // let newEditorState = EditorState.acceptSelection(
      //   editorState,
      //   editorState &&
      //     (editorState?.getSelection?.()?.isCollapsed() ||
      //       (windowsSelection.anchorOffset === editorRef.current.getSelection().anchorOffset &&
      //         windowsSelection.focusOffset === editorRef.current.getSelection().focusOffset))
      //     ? editorState.getSelection()
      //     : emptySelection
      // );

      // let newEditorState = EditorState.acceptSelection(
      //   editorState,
      //   editorState.getSelection()
      // );
      // console.log(editorState &&
      //   (editorState?.getSelection?.()?.isCollapsed() ||
      //     (windowsSelection.anchorOffset === editorRef.current.getSelection().anchorOffset &&
      // windowsSelection.focusOffset === editorRef.current.getSelection().focusOffset)));

      // let newEditorState = state.editorState;
      // const selectionNew = editorRef.current?.getSelection();

      // if (state.aISelectionState) {
      //   newEditorState = EditorState.forceSelection(newEditorState, state.aISelectionState);
      //   newEditorState = _clearFormatting(newEditorState, false);
      // }
      // // console.log('2');

      // newEditorState = EditorState.forceSelection(newEditorState, selectionNew);

      if (
        (displayAIToolBar.current || displayAIToolBarCursor.current) &&
        (editorState?.getSelection().isCollapsed() ||
          (editorState?.getSelection().isCollapsed() !== false &&
            !editorState?.getSelection()?.getHasFocus()))
        // (!editorState?.getSelection().isCollapsed() && editorState?.getSelection()?.getHasFocus()))
      ) {
        console.log(
          '90'
          // , editorState.getSelection(), window.getSelection(), editorState?.getSelection?.()?.isCollapsed(),
          // windowsSelection.anchorOffset,
          // windowsSelection.focusOffset
        );
        displayAIToolBar.current = '';
        displayAIToolBarCursor.current = '';
        setAskAI(false);
        dispatch({ type: 'setAISelectionState', aISelectionState: '' });
        // let newEditorState = _clearFormatting(editorState, false);
        // dispatch({ type: 'setEditorState', editorState: newEditorState });
        // editorRef.current = newEditorState;
        // if (state.aISelectionState) {
        // editorState = EditorState.forceSelection(editorState, state.aISelectionState);
        // editorState = _clearFormatting(editorState, false);
        // const currentSelection = editorState.getSelection();
        // console.log(currentSelection, 'currentSelection');
        // const blockKey = currentSelection.getFocusKey();
        // const anchorOffset = currentSelection.getAnchorOffset()
        // const newSelection = currentSelection.merge({
        //   anchorKey: blockKey,
        //   anchorOffset: anchorOffset,
        //   focusKey: blockKey,
        //   focusOffset: anchorOffset,
        //   isBackward: false,
        // });
        // editorState = EditorState.acceptSelection(editorState, newSelection);
        // }
        dispatch({ type: 'setEditorState', editorState: editorState });
        editorRef.current = editorState;
        // editor.current.blur();
      } else if (type === 'inside' && displayAIToolBar.current) {
        // let newEditorState = EditorState.forceSelection(
        //   editorState,
        //   editorState.getSelection()
        // );
        // const currentSelection = editorState.getSelection();
        // const emptySelection = new SelectionState({
        //   anchorKey: currentSelection.getFocusKey(),
        //   anchorOffset: currentSelection.getFocusOffset(),
        //   focusKey: currentSelection.getFocusKey(),
        //   focusOffset: currentSelection.getFocusOffset(),
        //   hasFocus: false,
        // });
        console.log(
          '91'
          // , editorState.getSelection(), selection
        );

        // let newEditorState1 = EditorState.acceptSelection(editorState, emptySelection);
        // const newEditorState2 = EditorState.createWithContent(editorState.getCurrentContent());

        // editorRef.current = newEditorState2;
        // dispatch({ type: 'setEditorState', editorState: newEditorState2 });
        if (state.aISelectionState) {
          editorState = EditorState.forceSelection(editorState, state.aISelectionState);
          // if (!isCursorAtEndOfBlock()) { // commented for - Click on new empty line
          editorState = _clearFormatting(editorState, false);
          // }
          // if (isOutsideText) { // handle outside click of block - Selection not disappear on single word selection
          //   editorState = EditorState.forceSelection(editorState, editorRef?.current?.getSelection());
          //   dispatch({
          //     type: 'setAISelectionState',
          //     aISelectionState: editorRef?.current?.getSelection(),
          //   });
          // } else {
          //   if (navigator.userAgent.includes("Chrome") && navigator) {
          //     console.log('93');
          //     // editorState = _clearFormatting(editorState, false);
          //     // SelectionState.createEmpty().set('hasFocus', false)
          //   }

          editorState = EditorState.forceSelection(editorState, editorRef?.current?.getSelection());
          // displayAIToolBar.current = '';
          dispatch({
            type: 'setAISelectionState',
            aISelectionState: editorRef?.current?.getSelection(),
          });
          console.log('92');
          // const emptySelection = new SelectionState({
          //   anchorKey: currentSelection.getFocusKey(),
          //   anchorOffset: currentSelection.getFocusOffset(),
          //   focusKey: currentSelection.getFocusKey(),
          //   focusOffset: currentSelection.getFocusOffset(),
          //   hasFocus: false,
          // });
          // editorState = EditorState.forceSelection(editorState, emptySelection);
          // dispatch({
          //   type: 'setAISelectionState',
          //   aISelectionState: emptySelection,
          // });
          // }

          // displayAIToolBar.current = '';
          // displayAIToolBarCursor.current = '';
          // setAskAI(false);
          // dispatch({
          //   type: 'setAISelectionState',
          //   aISelectionState: editorRef?.current?.getSelection(),
          // });
        }

        // let newEditorState = EditorState.createWithContent(
        //   convertFromRaw(raw, extendedBlockRenderMap)
        // );
        // displayAIToolBar.current = '';
        // displayAIToolBarCursor.current = '';
        // setAskAI(false);
        // dispatch({ type: 'setAISelectionState', aISelectionState: '' });
        // if (!isCursorAtEndOfBlock()) {
        //   newEditorState = _clearFormatting(newEditorState, false);
        // }
        // const emptySelection = SelectionState.createEmpty().set('hasFocus', false);
        // newEditorState = EditorState.forceSelection(newEditorState, emptySelection);
        // editorRef.current = newEditorState;
        // dispatch({ type: 'setEditorState', editorState: newEditorState });
        // dispatch({
        //   type: 'setInlineToolbar',
        //   inlineToolbar: {
        //     show: false,
        //   },
        // });

        dispatch({ type: 'setEditorState', editorState: editorState });
        editorRef.current = editorState;

        // focusEditor();
        // editor.current.blur();
      }
    }
  };

  // calls update draft hooks to avoid cancellation during rerendering
  const saveDraft = proposalUtils.updateDraftHook(saveProposalDraft, setDisablePreview)(
    sectionName,
    sectionType
  );

  const handleEditorChange = ({ es, force = false, update, scrollActive, ...rest }) => {
    if ((update === 'table' || update === 'form') && updateLoaderState) {
      updateLoaderState(true);
    }

    editorChange({
      ...rest,
      editorState: es,
      props: { ...props, saveDraft },
      dispatch,
      editorRef,
      updateSelection: utils.updateSelection,
      force,
      editorCallback: () => {
        if ((update === 'table' || update === 'form') && updateLoaderState) {
          updateLoaderState(false);
        }
        if (scrollActive) {
          scrollToSection({ editorState: es });
        }
        if (displayAIToolBar.current && !state.editorState?.getSelection().isCollapsed()) {
          updateSelectionInlineToolbar(props.id, props.prop.language, dispatch);
        }
        aiSaving.current = false;
      },
    });
  };

  const handleTextInsert = async (editorState, insertAfterBlock) => {
    const contentState = editorState?.getCurrentContent();
    const contentBlock = contentState?.getBlockMap();

    if (contentBlock?.size < 2 || insertAfterBlock) {
      const newBlock = new ContentBlock({
        key: genKey(),
        type: 'unstyled',
        text: '',
        characterList: Immutable.List(),
      });
      let newBlockMap;

      if (insertAfterBlock) {
        // Split the blocks
        const blocksBefore = contentBlock.toSeq().takeUntil(function (v) {
          return v === currentBlock;
        });

        const blocksAfter = contentBlock
          .toSeq()
          .skipUntil(function (v) {
            return v === currentBlock;
          })
          .rest();

        const currentBlock = contentState.getBlockForKey(editorState.getSelection().getEndKey());

        const newBlocks = [
          [currentBlock.getKey(), currentBlock],
          [
            newBlock.key,
            new ContentBlock({
              key: newBlock.key,
              type: 'unstyled',
              text: '',
              characterList: Immutable.List(),
            }),
          ],
        ];
        newBlockMap = blocksBefore.concat(newBlocks, blocksAfter).toOrderedMap();
      } else {
        newBlockMap = contentState.getBlockMap().set(newBlock.key, newBlock);
      }

      const newEditorState = await EditorState.push(
        editorState,
        ContentState.createFromBlockArray(newBlockMap.toArray())
          .set('selectionBefore', contentState.getSelectionBefore())
          .set('selectionAfter', contentState.getSelectionAfter())
      );

      const newSelection = new SelectionState({
        anchorKey: newBlock.key,
        anchorOffset: 0,
        focusKey: newBlock.key,
        focusOffset: 0,
        hasFocus: true,
      });

      editorState = await EditorState.acceptSelection(newEditorState, newSelection);
    } else {
      editorState = await RichUtils.insertSoftNewline(
        RichUtils.insertSoftNewline(editorState || state.editorState)
      );
    }

    handleEditorChange({
      es: editorState,
      scrollActive: true,
    });
    dragStartRef.current = false;
  };

  const _toggleBlockType = async (
    blockType,
    customBlock = false,
    editorState,
    isActive = false
  ) => {
    editorState = editorState || editorRef?.current || state.editorState;

    const selectionState = state.aISelectionState || editorState.getSelection();
    let contentState = editorState.getCurrentContent();
    const startKey = selectionState?.getStartKey();
    const contentBlock = contentState?.getBlockForKey(startKey);

    const data = contentBlock?.getData();

    const needRemoval = isActive !== blockType ? true : false;

    if (customBlock) {
      if (data?.size && isActive === blockType) {
        contentState = Modifier.setBlockData(contentState, selectionState, Immutable.Map({}));
        editorState = EditorState.push(editorState, contentState, 'change-block-data'); //EditorState.createWithContent(contentState);
      } else {
        contentState = Modifier.setBlockData(
          contentState,
          selectionState,
          blockType === 'none' ? Immutable.Map({}) : Immutable.Map({ className: blockType })
        );
        editorState = EditorState.push(editorState, contentState, 'change-block-data'); //EditorState.createWithContent(contentState);
      }
      blockType = blockType === 'none' ? '' : customBlock;
    } else {
      if (data.size) {
        contentState = Modifier.setBlockData(contentState, selectionState, Immutable.Map({}));
        editorState = EditorState.push(editorState, contentState, 'change-block-data'); // EditorState.createWithContent(contentState);
      }
    }

    if (
      (blockType === 'ordered-list-item' || blockType === 'unordered-list-item') &&
      isActive !== 'unstyled' &&
      needRemoval
    ) {
      editorState = await RichUtils.toggleBlockType(editorState, isActive);
    } else if (
      (blockType === 'ordered-list-item' || blockType === 'unordered-list-item') &&
      isActive !== customBlock &&
      blockType !== isActive &&
      isActive !== 'unstyled' &&
      needRemoval
    ) {
      const { editorState: newEditorState } = utils.insertEmptyBlock('before', editorState);
      editorState = newEditorState;
    }

    handleEditorChange({
      es: await RichUtils.toggleBlockType(
        EditorState.forceSelection(editorState, selectionState),
        blockType
      ),
      force: true,
    });
  };

  const handleImageInsert = (editorState) => {
    /*
    prevent opening multiple file upload window, which is caused due to 
    calling handleImageInsert() multiple times from useEffect below
    */
    // if(!clickCounterRef?.current){
    //   console.log('loger 349');
    //   return;
    // }
    dispatch({ type: 'setDropEditorState', dropEditorState: editorState || state.editorState });
    clickCounterRef.current = null;
    imageInputRef.current.click();
  };

  const handleInsertFromMedia = async (src, editorState) => {
    // todo:  handle other files(which are not image), progress
    if (editorState) {
      dispatch({ type: 'setDropEditorState', dropEditorState: editorState });
    }

    editorState = editorState || state.editorState;

    const url = await copy({
      fromUrl: src,
      prefix: `props/${prop._id}`,
    });

    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('image', 'IMMUTABLE', {
      src: url,
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    handleEditorChange({
      es: AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' '),
      scrollActive: true,
    });
  };

  const handleInsertVideo = (editorState) => {
    dispatch({ type: 'setVideoDialog', videoDialog: editorState || state.editorState });
  };

  const handleInsertGallery = async (editorState) => {
    const isDropable = commonUtils.calculatePricingDropable(titleSpan, sectionSpan);
    if (!isDropable) {
      return;
    }
    editorState = editorState || state.editorState;

    const data = [];
    const contentState = editorState.getCurrentContent();

    const contentStateWithEntity = contentState.createEntity('gallery', 'MUTABLE', { data }); // ,
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const es = await AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
    handleEditorChange({
      es,
      scrollActive: true,
    });
  };

  const handleInsertTable = async (editorState) => {
    const isDropable = commonUtils.calculatePricingDropable(titleSpan, sectionSpan);
    if (!isDropable) {
      return;
    }

    let newEditorState = editorState || state.editorState;

    const data = [];
    const row = 3;
    const column = 3;

    for (let r = 0; r < row; r++) {
      data[r] = {};
      data[r].id = r + 1;
      for (let c = 0; c < column; c++) {
        data[r][c + 1] = '-';
      }
    }

    const contentState = newEditorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('table', 'MUTABLE', {
      data,
      config: { topRowColor: 'lightgrey', rowColor: 'white' },
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const es = await AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');

    handleEditorChange({
      es,
      update: 'table',
      scrollActive: true,
    });

    dispatch({ type: 'setDropEditorState', dropEditorState: '' });
  };

  const handleInsertTestimonial = async (editorState) => {
    const isDropable = commonUtils.calculatePricingDropable(titleSpan, sectionSpan);
    if (!isDropable) {
      return;
    }
    let newEditorState = editorState || state.editorState;

    const contentState = newEditorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('testimonial', 'MUTABLE', {
      data: [testimonial.data, testimonial.data, testimonial.data],
      config: {},
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const es = await AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');

    handleEditorChange({
      es,
      update: 'testimonial',
      scrollActive: true,
    });

    dispatch({ type: 'setDropEditorState', dropEditorState: '' });
  };

  const handleInsertForm = async (editorState) => {
    const isDropable = commonUtils.calculatePricingDropable(titleSpan, sectionSpan);
    if (!isDropable) {
      return;
    }
    editorState = editorState || state.editorState;

    const data = [...defaultFormFields];
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('form', 'MUTABLE', {
      data: data,
      config: { editable: true, columns: 1 },
      language: prop?.language?.toLowerCase(),
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const es = await AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
    handleEditorChange({
      es,
      update: 'form',
      scrollActive: true,
    });
  };

  const handleInsertPriceMiles = async (type, editorState) => {
    const isDropable = commonUtils.calculatePricingDropable(titleSpan, sectionSpan);
    if (!isDropable) {
      return;
    }
    const es = await utils.insertTeXBlock(editorState || state.editorState, type);

    dispatch({ type: 'setLiveTeXEdits', liveTeXEdits: Map() });
    dispatch({ type: 'setEditorState', editorState: es });
    editorRef.current = es;
    setTimeout(() => focusEditor(), 0);
  };

  const handleInsertDivider = async (editorState) => {
    editorState = editorState || state.editorState;

    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('divider', 'IMMUTABLE', {});
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });

    const es = await AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
    handleEditorChange({
      es,
      scrollActive: true,
    });
  };

  const handleInsertHTML = (editorState) => {
    dispatch({
      type: 'setHtmlEdit',
      htmlEdit: { htmlCode: '', editorState: editorState || state.editorState },
    });
  };

  const insertImage = async (event) => {
    event.preventDefault();
    const { files } = event.target;
    if (files && !files.length) return;
    const [file] = files;
    const { url } = await upload(file, `props/${prop._id}/section`);

    const img = new Image();
    img.src = url;
    img.onload = async () => {
      const { width, height } = img;
      const config = {
        size: {
          width,
          height,
          ratio: parseFloat((width / height).toFixed(2)),
        },
        imageType: file.type.substring(6), // add image type
      };

      const editorState = state.dropEditorState || state.editorState;
      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity('image', 'IMMUTABLE', {
        src: url,
        config,
      });
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const es = await AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');

      handleEditorChange({
        es,
        scrollActive: true,
      });
      dispatch({ type: 'setDropEditorState', dropEditorState: '' });
      event.target.value = null;
    };
  };

  const handleDropImage = async (files) => {
    try {
      const [file] = files;
      if (!file?.type) {
        throw new Error('Please select an image');
      }
      if (!file.type.startsWith('image/')) {
        throw new Error('Only image is allowed.');
      }
      setImageName(file.name);
      toggleImageProgress(true);

      await resizeImageForS3({ file, size: {}, path: 'sections' });
      const { url } = await upload(file, `props/${prop._id}/section`);
      const { editorState } = state;

      const img = new Image();
      img.src = url;
      img.onload = async () => {
        const { width, height } = img;
        const config = {
          size: {
            // width, // width will be calculated on first image load
            // height,
            ratio: parseFloat((width / height).toFixed(2)),
          },
          imageType: file.type.substring(6),
        };

        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity('image', 'IMMUTABLE', {
          src: url,
          config,
        });

        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const es = await AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');

        handleEditorChange({
          es,
          scrollActive: true,
        });
      };
    } catch (error) {
      message.error(error.message);
    }
    toggleImageProgress(false);
  };

  const onImageDrop = (e) => {
    e.preventDefault();
    handleDropImage(e.dataTransfer.files);
  };

  const handleImageChange = (ev) => {
    handleDropImage(ev.target.files);
  };

  const handleImageDragOver = (e) => {
    e.preventDefault();
  };

  const handleDirectImageInsert = async (src) => {
    const url = await copy({
      fromUrl: src,
      prefix: `props/${prop._id}`,
    });
    const { editorState } = state;

    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('image', 'IMMUTABLE', {
      src: url,
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const es = await AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');

    handleEditorChange({
      es,
      scrollActive: true,
    });
  };

  useEffect(() => {
    if (selectedBlock && element) {
      if (element === 'text') handleTextInsert();
      else if (element === 'blockquote') _toggleBlockType(element);
      else if (element === 'image') handleImageInsert();
      else if (element === 'video') handleInsertVideo();
      else if (element === 'gallery') handleInsertGallery();
      else if (element === 'table') handleInsertTable();
      else if (element === 'testimonial') handleInsertTestimonial();
      else if (element === 'form') handleInsertForm();
      else if (element === 'price') handleInsertPriceMiles('price', editorRef?.current);
      else if (element === 'miles') handleInsertPriceMiles('miles');
      else if (element === 'divider') handleInsertDivider();
      else if (element === 'add-media-image') handleDirectImageInsert(elementValue);
      else if (element === 'html') handleInsertHTML();
      setElement('');
    } else if (element) {
      setElement('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element, elementValue]);

  const promptForVariable = (editorState, onlyOpenToolBar, variableName) => {
    variableName = variableName.replace('{{', '');
    variableName = variableName.replace('}}', '');
    const [category] = variableName.split('.');

    if (onlyOpenToolBar) {
      setScrollingTo(category);
    }

    setScrollingTo(category);

    if (!onlyOpenToolBar) {
      const selection = editorState.getSelection();
      dispatch({ type: 'setSelectionState', selectionState: selection });
      // dispatch({ type: 'setEditorReadOnly', editorReadOnly: true });
      dispatch({ type: 'setDisplayVariableInput', displayVariableInput: true });
    }

    setOpenVariablesList(true);
  };

  const promptForLink = (type = 'fresh-link', text, url) => {
    const { editorState } = state;
    const selection = editorState.getSelection();
    displayAIToolBar.current = '';
    displayAIToolBarCursor.current = '';
    if (type === 'fresh-link' && selection.isCollapsed()) {
      return;
    }
    const isUpdate = type === 'update-link-text';
    dispatch({ type: 'setDisplayLink', displayLink: type });
    dispatch({ type: 'setLinkTextValue', linkTextValue: isUpdate ? text : '' });
    dispatch({ type: 'setLinkUrlValue', linkUrlValue: isUpdate ? url : '' });
    dispatch({ type: 'setSelectionState', selectionState: selection });
    setIsValidUrl(isUpdate);
  };
  // Link ends here

  // Color starts here
  const promptColorBox = (type = 'COLOR') => {
    // type = COLOR or BACKGROUND
    const editorState = editorRef.current || state.editorState;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      dispatch({ type: 'setSelectionState', selectionState: selection });
      dispatch({ type: 'setDisplayColorPicker', displayColorPicker: type });
      dispatch({ type: 'setEditorReadOnly', editorReadOnly: true });
      highlightSelection('add');
    }
  };

  const closeColor = () => {
    if (!state?.displayColorPicker) {
      return;
    }
    dispatch({ type: 'setDisplayColorPicker', displayColorPicker: false });
    dispatch({ type: 'setEditorReadOnly', editorReadOnly: false });
    highlightSelection('remove');
  };
  // Color ends here

  const highlightSelection = (type = 'remove') => {
    // add or remove fake text selection highlight
    const editorState = editorRef.current || state.editorState;
    const selectionState = editorState.getSelection();

    if (selectionState && !editorState.getSelection().isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      let newContentState = contentState;
      newContentState = Modifier.removeInlineStyle(contentState, selectionState, 'HIGHLIGHT-AI');

      if (type === 'remove') {
        // remove highlight
        newContentState = Modifier.removeInlineStyle(contentState, selectionState, 'HIGHLIGHT');
      } else if (type === 'add') {
        const contentState = editorState.getCurrentContent();
        const blocks = contentState.getBlockMap();

        blocks.forEach((block) => {
          // remove HIGHLIGHT from the entire block
          newContentState = Modifier.removeInlineStyle(
            newContentState,
            getBlockSelection(block),
            'HIGHLIGHT'
          );
        });

        // apply highlight only to the selected text
        newContentState = Modifier.applyInlineStyle(newContentState, selectionState, 'HIGHLIGHT');
      }

      handleEditorChange({
        es: RichUtils.toggleBlockType(
          EditorState.push(editorState, newContentState, 'change-inline-style'),
          ''
        ),
        // focusChanged: type === 'add',
      });
    }
  };

  const _toggleCustomBockStyle = ({ blockType, type }) => {
    let { editorState } = state;
    let selection = editorState.getSelection();
    let startKey = selection.getStartKey();
    let endKey = selection.getEndKey();
    let currentContent = editorState.getCurrentContent();
    let prevBlockTypes = RichUtils.getCurrentBlockType(editorState);
    let newBlockType = '';
    let blocksToUpdate = [];
    let blockKey = startKey;
    while (blockKey !== endKey) {
      blocksToUpdate.push(blockKey);
      blockKey = currentContent.getKeyAfter(blockKey);
    }
    blocksToUpdate.push(endKey);

    prevBlockTypes = prevBlockTypes.split(' ');

    let directionBlockType = prevBlockTypes.find((prevBlockType) =>
      prevBlockType.match(/direction/)
    );
    let alignBlockType = prevBlockTypes.find((prevBlockType) => prevBlockType.match(/align/));
    let heightBlockType = prevBlockTypes.find((prevBlockType) => prevBlockType.match(/height/));
    let intentBlockType = prevBlockTypes.find((prevBlockType) => prevBlockType.match(/intent/));
    let listBlockType = prevBlockTypes.find((prevBlockType) => prevBlockType.match(/list-item/));

    let newContentState = currentContent;
    // Update className for each block
    if (type === 'align') {
      if (blockType === alignBlockType) alignBlockType = '';
      else if (blockType !== 'unstyled') alignBlockType = blockType;
      else alignBlockType = '';
    } else if (type === 'direction') {
      if (directionBlockType && blockType !== 'unstyled') {
        if (directionBlockType.match(/ltr/)) {
          directionBlockType = 'editor-direction-rtl';
        } else {
          directionBlockType = 'editor-direction-ltr';
        }
      } else if (blockType !== 'unstyled') directionBlockType = 'editor-direction-rtl';
      else directionBlockType = '';
    } else if (type === 'height') {
      if (listBlockType) {
        blocksToUpdate.forEach((blockKey) => {
          let block = newContentState.getBlockForKey(blockKey);
          let blockData = block.getData();
          prevBlockTypes = prevBlockTypes.filter(
            (className) => !className.startsWith('line-height__')
          );
          if (blockType !== 'unstyled') {
            prevBlockTypes.push(blockType);
          }

          // Merge updated data into the block
          let newData = blockData.set('className', prevBlockTypes.join(' '));
          let newBlock = block.merge({ data: newData });

          // Update the content state with the modified block
          newContentState = newContentState.merge({
            blockMap: newContentState.getBlockMap().set(blockKey, newBlock),
          });
        });
      } else if (blockType === heightBlockType) heightBlockType = '';
      else if (blockType !== 'unstyled') heightBlockType = blockType;
      else heightBlockType = '';
    } else if (type === 'intent') {
      let intentCount = intentBlockType?.split('-')[2] || 0;
      if (intentCount < 26 && intentCount >= 0) {
        if (blockType.match(/left/)) isRtl ? ++intentCount : --intentCount;
        else if (blockType.match(/right/)) isRtl ? --intentCount : ++intentCount;
      }

      intentBlockType = `intent-${isRtl ? 'right' : 'left'}-${intentCount}`;
    }

    newBlockType = `${alignBlockType || ''}  ${directionBlockType || ''} ${heightBlockType || ''} ${
      intentBlockType || ''
    } ${listBlockType || ''}`;

    // Update the editor state with the modified content
    const isLineHeight = type === 'height' && listBlockType;

    handleEditorChange({
      es: isLineHeight
        ? EditorState.push(editorState, newContentState, newBlockType)
        : RichUtils.toggleBlockType(editorState, newBlockType),
    });
  };

  // Styles toogle starts here
  const _toggleInlineStyle = ({ inlineStyle, es, withoutText }) => {
    if (inlineStyle === 'LINK') {
      if (withoutText) promptForLink('fresh-link-text');
      else promptForLink('fresh-link');
    } else if (inlineStyle === 'COLOR' || inlineStyle === 'BACKGROUND') {
      promptColorBox(inlineStyle);
      // dispatch({ type: 'setDisplayAIToolBar', displayAIToolBar: '' });
      displayAIToolBar.current = '';
      displayAIToolBarCursor.current = '';
    } else if (inlineStyle === 'right-intent' || inlineStyle === 'left-intent') {
      _toggleCustomBockStyle({
        blockType: inlineStyle,
        type: 'intent',
      });
    } else {
      if (inlineStyle.includes('rgba') && type !== 'header') {
        debounceColorChange(es, inlineStyle);
      } else {
        handleEditorChange({
          es: RichUtils.toggleInlineStyle(es || state.editorState, inlineStyle),
        });
      }
    }
  };

  const debounceColorChange = useRef(
    _.debounce((es, inlineStyle) => {
      handleEditorChange({ es: RichUtils.toggleInlineStyle(es || state.editorState, inlineStyle) });
    }, 500)
  ).current;

  const _toggleFontInlineStyle = ({ inlineStyle, type, fontFamilies }) => {
    const editorState = editorRef.current || state.editorState;
    const selection = editorState.getSelection();

    let nextContentState = '';

    if (type === 'family') {
      // Let's just allow one font at a time. Turn off all active fonts.
      fontFamilies = fontFamilies.map((fontList) => fontList.family);
      nextContentState = fontFamilies.reduce((contentState, font) => {
        return Modifier.removeInlineStyle(contentState, selection, font);
      }, editorState.getCurrentContent());
    } else if (type === 'weight') {
      // Let's just allow one weight at a time. Turn off all active weight.
      nextContentState = fontWeights.reduce((contentState, weight) => {
        return Modifier.removeInlineStyle(contentState, selection, `${weight}`);
      }, editorState.getCurrentContent());
    } else if (type === 'size' || type === 'increase-size' || type === 'decrease-size') {
      // Let's just allow one size at a time. Turn off all active size.
      nextContentState = fontSizes.reduce((contentState, size) => {
        return Modifier.removeInlineStyle(contentState, selection, size);
      }, editorState.getCurrentContent());
    }

    if (type === 'increase-size' || type === 'decrease-size') {
      const _currentStyle = editorState.getCurrentInlineStyle();

      let fontSizeIndex = fontSizes.indexOf('16px');

      // eslint-disable-next-line no-unused-vars
      _currentStyle.find((style) => {
        const index = fontSizes.indexOf(style);
        if (index > -1) {
          fontSizeIndex = index;
          return true;
        }
        return false;
      });

      if (type === 'increase-size') {
        fontSizeIndex = Math.min(fontSizeIndex + 1, fontSizes.length - 1);
      } else if (type === 'decrease-size') {
        fontSizeIndex = Math.max(fontSizeIndex - 1, 0);
      }
      inlineStyle = fontSizes[fontSizeIndex];
    }

    let nextEditorState = EditorState.push(editorState, nextContentState, 'change-inline-style');
    const currentStyle = editorState.getCurrentInlineStyle();

    // Unset style override for current style.
    if (selection.isCollapsed()) {
      nextEditorState = currentStyle.reduce((currentState, font) => {
        return RichUtils.toggleInlineStyle(currentState, font);
      }, nextEditorState);
    }

    // If the style is being toggled on, apply it.
    if (!currentStyle.has(inlineStyle)) {
      nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, `${inlineStyle}`);
    }

    handleEditorChange({ es: nextEditorState });
  };

  const _clearFormatting = (es, clearBlockType) => {
    const editorState = es || editorRef.current || state.editorState;

    const selectionState = editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const focusKey = selectionState.getFocusKey();
    const currentContent = editorState.getCurrentContent();
    const currentBlock = currentContent.getBlockForKey(anchorKey);
    const selectionStart = selectionState.getStartOffset();
    const selectionEnd = selectionState.getEndOffset();

    if (anchorKey === focusKey) {
      let newEditorState = editorState;
      if (clearBlockType) {
        const selectedText = currentBlock.getText().slice(selectionStart, selectionEnd);
        const contentWithoutStyles = Modifier.replaceText(
          editorState.getCurrentContent(),
          selectionState,
          selectedText,
          null
        );

        newEditorState = EditorState.push(editorState, contentWithoutStyles, 'change-inline-style');
        newEditorState = RichUtils.toggleBlockType(newEditorState, '');
      } else {
        if (dragStartRef.current) {
          // doesnot removed selection when drag and drop text starts
          return editorState;
        }
        let selectionState = editorState.getSelection();

        if ((selectionState && !selectionState.isCollapsed()) || state.aISelectionState) {
          const contentState = editorState.getCurrentContent();
          const blocks = contentState.getBlockMap();
          // const selected = getFragmentFromSelection(editorState);
          let newContentState = contentState;
          console.log('56');

          blocks.forEach((block) => {
            // remove HIGHLIGHT from the entire block
            newContentState = Modifier.removeInlineStyle(
              newContentState,
              getBlockSelection(block),
              'HIGHLIGHT-AI'
            );
          });

          // newEditorState = EditorState.push(editorState, newContentState, 'change-inline-style');

          // let selectedText = selected ? selected.map((x) => x.getText()).join('\n') : '';
          // // const startKey = selectionState.getStartKey();
          // // const startOffset = selectionState.getStartOffset();
          // const block = editorState.getCurrentContent().getBlockForKey(selectionState.getFocusKey());
          // // const characterMetadata = block.getCharacterList().get(startOffset);
          // // console.log(selectionState.getFocusOffset(), block.getText(), '=-');

          // if (selectedText) {
          //   // remove highlight
          //   newContentState = Modifier.removeInlineStyle(contentState, selectionState, 'HIGHLIGHT-AI');
          newEditorState =
            // RichUtils.toggleBlockType(
            EditorState.push(editorState, newContentState, 'change-inline-style');
          // ''
          // );
          // }
        }
        // const styles = ['bg-rgba(24, 144, 255, 1)', 'rgba(255, 255, 255, 1)'];

        // const contentWithoutStyles = _.reduce(
        //   styles,
        //   (newContentState, style) =>
        //     Modifier.removeInlineStyle(newContentState, editorState.getSelection(), style),
        //   editorState.getCurrentContent()
        // );

        // newEditorState = EditorState.push(editorState, contentWithoutStyles, 'change-inline-style');
      }

      if (es) {
        return newEditorState;
      }

      handleEditorChange({ es: newEditorState });
    } else {
      let headerRaw = '';
      let rawBlocks = [];
      if (sectionName === 'header') {
        let shouldBreak = false;
        for (const key of Object.keys(prop?.draft[sectionName])) {
          if (key.indexOf('raw') > -1) {
            for (const block of prop?.draft[sectionName][key].blocks) {
              if (block.key === focusKey || block.key === anchorKey) {
                shouldBreak = true;
                rawBlocks = JSON.parse(JSON.stringify(prop?.draft[sectionName][key].blocks));
                headerRaw = key;
                break;
              }
            }
          }
          if (shouldBreak) {
            break;
          }
        }
      } else {
        rawBlocks = JSON.parse(JSON.stringify(prop?.draft[sectionName]?.raw?.blocks));
      }
      let shouldSelect = false;

      if (selectionState.getIsBackward()) {
        for (let i = 0; i < rawBlocks.length; i++) {
          if (focusKey === rawBlocks[i].key) {
            shouldSelect = true;
            const inlineStyleRanges = [];
            for (let j = 0; j < rawBlocks[i].inlineStyleRanges.length; j++) {
              if (rawBlocks[i].inlineStyleRanges[j].offset < selectionStart) {
                if (
                  rawBlocks[i].inlineStyleRanges[j].offset +
                    rawBlocks[i].inlineStyleRanges[j].length >
                  selectionStart
                ) {
                  inlineStyleRanges.push({
                    ...rawBlocks[i].inlineStyleRanges[j],
                    length:
                      rawBlocks[i].inlineStyleRanges[j].offset +
                      rawBlocks[i].inlineStyleRanges[j].length -
                      selectionStart,
                  });
                } else {
                  inlineStyleRanges.push(rawBlocks[i].inlineStyleRanges[j]);
                }
              }
            }
            rawBlocks[i].inlineStyleRanges = inlineStyleRanges;
            if (rawBlocks[i].type !== 'atomic') {
              rawBlocks[i].type = 'unstyled';
            }
          } else if (rawBlocks[i].key === anchorKey) {
            shouldSelect = false;
            const inlineStyleRanges = [];
            for (let j = 0; j < rawBlocks[i].inlineStyleRanges.length; j++) {
              if (rawBlocks[i].inlineStyleRanges[j].offset > selectionEnd) {
                inlineStyleRanges.push(rawBlocks[i].inlineStyleRanges[j]);
              } else if (
                rawBlocks[i].inlineStyleRanges[j].offset +
                  rawBlocks[i].inlineStyleRanges[j].length >
                selectionEnd
              ) {
                inlineStyleRanges.push({
                  ...rawBlocks[i].inlineStyleRanges[j],
                  offset: selectionEnd,
                });
              }
            }
            rawBlocks[i].inlineStyleRanges = inlineStyleRanges;
            if (rawBlocks[i].type !== 'atomic') {
              rawBlocks[i].type = 'unstyled';
            }
          } else if (shouldSelect) {
            rawBlocks[i].inlineStyleRanges = [];
            if (rawBlocks[i].type !== 'atomic') {
              rawBlocks[i].type = 'unstyled';
            }
          }
        }
      } else {
        for (let i = 0; i < rawBlocks.length; i++) {
          if (anchorKey === rawBlocks[i].key) {
            shouldSelect = true;
            const inlineStyleRanges = [];
            for (let j = 0; j < rawBlocks[i].inlineStyleRanges.length; j++) {
              if (rawBlocks[i].inlineStyleRanges[j].offset < selectionStart) {
                if (
                  rawBlocks[i].inlineStyleRanges[j].offset +
                    rawBlocks[i].inlineStyleRanges[j].length >
                  selectionStart
                ) {
                  inlineStyleRanges.push({
                    ...rawBlocks[i].inlineStyleRanges[j],
                    length:
                      rawBlocks[i].inlineStyleRanges[j].offset +
                      rawBlocks[i].inlineStyleRanges[j].length -
                      selectionStart,
                  });
                } else {
                  inlineStyleRanges.push(rawBlocks[i].inlineStyleRanges[j]);
                }
              }
            }
            rawBlocks[i].inlineStyleRanges = inlineStyleRanges;
            if (rawBlocks[i].type !== 'atomic') {
              rawBlocks[i].type = 'unstyled';
            }
          } else if (rawBlocks[i].key === focusKey) {
            shouldSelect = false;
            const inlineStyleRanges = [];
            for (let j = 0; j < rawBlocks[i].inlineStyleRanges.length; j++) {
              if (rawBlocks[i].inlineStyleRanges[j].offset > selectionEnd) {
                inlineStyleRanges.push(rawBlocks[i].inlineStyleRanges[j]);
              } else if (
                rawBlocks[i].inlineStyleRanges[j].offset +
                  rawBlocks[i].inlineStyleRanges[j].length >
                selectionEnd
              ) {
                inlineStyleRanges.push({
                  ...rawBlocks[i].inlineStyleRanges[j],
                  offset: selectionEnd,
                });
              }
            }
            rawBlocks[i].inlineStyleRanges = inlineStyleRanges;
            if (rawBlocks[i].type !== 'atomic') {
              rawBlocks[i].type = 'unstyled';
            }
          } else if (shouldSelect) {
            rawBlocks[i].inlineStyleRanges = [];
            if (rawBlocks[i].type !== 'atomic') {
              rawBlocks[i].type = 'unstyled';
            }
          }
        }
      }

      let newEditorState = editorState;

      if (clearBlockType) {
        newEditorState = EditorState.createWithContent(
          convertFromRaw({
            blocks: rawBlocks,
            entityMap:
              sectionName === 'header'
                ? prop?.draft[sectionName][headerRaw]?.entityMap
                : prop?.draft[sectionName]?.raw?.entityMap,
          }),
          decorator
        );
        newEditorState = RichUtils.toggleBlockType(newEditorState, '');
      } else {
        const selectionState = editorState.getSelection();

        if ((selectionState && !selectionState.isCollapsed()) || state.aISelectionState) {
          const contentState = editorState.getCurrentContent();
          const blocks = contentState.getBlockMap();
          // const selected = getFragmentFromSelection(editorState);
          let newContentState = contentState;

          // let selectedText = selected ? selected.map((x) => x.getText()).join('\n') : '';
          blocks.forEach((block) => {
            // remove HIGHLIGHT from the entire block
            newContentState = Modifier.removeInlineStyle(
              newContentState,
              getBlockSelection(block),
              'HIGHLIGHT-AI'
            );
          });

          // if (selectedText) {
          //   // remove highlight
          //   newContentState = Modifier.removeInlineStyle(contentState, selectionState, 'HIGHLIGHT-AI');
          newEditorState =
            // RichUtils.toggleBlockType(
            EditorState.push(editorState, newContentState, 'change-inline-style');
          //   //   ''
          //   // );
          // }
        }
        //   } else if (type === 'add') {
        // const styles = ['bg-rgba(24, 144, 255, 1)', 'rgba(255, 255, 255, 1)'];

        // const contentWithoutStyles = _.reduce(
        //   styles,
        //   (newContentState, style) =>
        //     Modifier.removeInlineStyle(newContentState, editorState.getSelection(), style),
        //   editorState.getCurrentContent()
        // );

        // newEditorState = EditorState.push(editorState, contentWithoutStyles, 'change-inline-style');
      }

      if (es) {
        return newEditorState;
      }

      handleEditorChange({ es: newEditorState });
    }
  };

  const _onSplitSection = async () => {
    updateLoaderState(true);
    const { editorState } = state;
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();

    let splitedContentState = Modifier.splitBlock(contentState, selectionState);
    let selectionAfter = splitedContentState.getSelectionAfter();

    let startBlock = selectionAfter.getStartKey();

    let blockAsArray = splitedContentState.getBlocksAsArray();

    let newBlock = [];
    let oldBlock = [];

    let ifReached = false;

    blockAsArray.forEach((block) => {
      if (ifReached || block.key === startBlock) {
        newBlock.push(block);
        ifReached = true;
      } else {
        oldBlock.push(block);
      }
    });

    let newContentState = ContentState.createFromBlockArray(newBlock);
    let oldContentState = ContentState.createFromBlockArray(oldBlock);

    const sectionId = uuidv4();

    // const newsection = {
    //   title: '<div></div>',
    //   text: '<div></div>',
    //   subtitle: '<div></div>',
    //   raw: convertToRaw(newContentState)
    // };

    const sectionOrder = helpers.findSectionOrder({
      proposal: prop,
      sectionId,
      sectionName,
      position: helpers.findNewSectionPosition({
        wrapperType: 'normal',
        sectionAlignment: 'below',
      }),
    });

    await saveProposal(
      {
        'draft.sectionorder': sectionOrder,
        [`draft.${sectionId}.raw`]: convertToRaw(newContentState),
        [`draft.${sectionName}.raw`]: convertToRaw(oldContentState),
      },
      () => {
        const newEditorState = EditorState.createWithContent(oldContentState);
        handleEditorChange({ es: newEditorState });
        updateLoaderState(false);
      }
    );
  };
  // Styles toogle ends here

  const onEdit = (x, type) => {
    let texobj;
    if (x && x.v) {
      texobj = null;
      if (x.v === -42) {
        // new pricing block
        dispatch({ type: 'setPriceEdit', priceEdit: x });
      } else if (x.v === -43) {
        // new miles block
        dispatch({ type: 'setTimeEdit', timeEdit: x });
      } else if (type === 'gallery') {
        dispatch({ type: 'setGalleryEdit', galleryEdit: x });
      } else if (type === 'html') {
        dispatch({ type: 'setHtmlEdit', htmlEdit: x });
      } else if (Array.isArray(x.v)) {
        dispatch({ type: 'setTableEdit', tableEdit: x });
      } else {
        try {
          texobj = JSON.parse(x.v);
        } catch (exx) {
          // console.log(exx);
        }
        if (texobj && texobj.deliverables) {
          dispatch({ type: 'setPriceEdit', priceEdit: x });
        } else if (texobj && texobj.milestones) {
          dispatch({ type: 'setTimeEdit', timeEdit: x });
        } else if (texobj && texobj.superPowers) {
          dispatch({ type: 'setSuperEdit', superEdit: x });
        }
      }
    }
  };

  // Render custom font color
  const customStyleFn = (styles) => {
    const output = {};
    const fontFamilies = Object.keys(state?.styleMap || {});
    const fontToBeLoad = [];

    // find font, color, background etc
    styles.forEach((value) => {
      const styleValue = value?.toString();

      // find fonts
      if (!fontLoadedVar()) {
        if (fontFamilies.includes(styleValue) && !fontLoaded.includes(styleValue)) {
          fontToBeLoad.push(styleValue);
          fontLoaded.push(styleValue);
        }
      }

      // find color
      if (styleValue?.startsWith('rgba')) {
        output.color = styleValue;
      }
      // find background color
      if (styleValue?.startsWith('bg-')) {
        output.backgroundColor = styleValue.substring(3);
      }
    });

    if (fontToBeLoad.length && !fontLoadedVar()) {
      const fontList = isRtl ? fontListHebrew : fontListEnglish;
      const prelist = fontList.filter((fontList) => fontToBeLoad.includes(fontList.family));

      loadProposalFont(prelist, prop?.language);
    }

    return output;
  };

  const handleMoveBlock = async ({ newEditorState, block, selection }) => {
    try {
      const es = await AtomicBlockUtils.moveAtomicBlock(newEditorState, block, selection);
      focusEditor();
      handleEditorChange({ es });
    } catch (error) {
      message.error('Block cannot be moved next to itself');
    }
  };

  const handleBackspace = (editorState, selection) => {
    const contentState = editorState.getCurrentContent();

    // Remove the selected text
    const newContentState = Modifier.removeRange(
      contentState,
      selection,
      'backward' // Can be 'backward' or 'forward' depending on how you want the range to be handled
    );

    // Push the new content state with the removed text to the editor state
    const newEditorState = EditorState.push(editorState, newContentState, 'remove-range');

    // Update the editor state with the new state
    handleEditorChange({ es: newEditorState });
  };

  const handleKeyBindingFn = (e) => {
    if (e.shiftKey && e.keyCode === 13) {
      return 'shift+enter';
    } else if (e.ctrlKey || e.metaKey) {
      if (e.keyCode === 75) {
        // ctrl + k = insert link

        e.preventDefault();
        _toggleInlineStyle({
          withoutText: state.inlineToolbar.show ? false : true,
          inlineStyle: 'LINK',
        });
        return 'handled';
      } else if (e.keyCode === 220) {
        // ctrl + \ = clear formatting
        _clearFormatting(null, true);
        return 'handled';
      } else if (e.keyCode === 88 && e.shiftKey) {
        // ctrl + shift + x = strike through
        _toggleInlineStyle({ inlineStyle: 'STRIKETHROUGH' });
        return 'handled';
      }
      // else if ((e.keyCode === 90 && e.shiftKey) || e.keyCode === 89) {
      //   e.preventDefault();
      //   dispatch({ type: 'setKeyType', keyType: e.shiftKey ? 'redo' : 'undo' });
      //   return 'handled';
      // } else if (e.keyCode === 90) {
      //   e.preventDefault();
      //   dispatch({ type: 'setKeyType', keyType: 'undo' });
      //   return 'handled';
      // }
      else if (e.keyCode === 89) {
        e.preventDefault();
        dispatch({ type: 'setKeyType', keyType: 'redo' });
        return 'handled';
      } else if (e.keyCode === 83) {
        e.preventDefault();
        handleEditorChange({ es: state.editorState });
        return 'handled';
      } else if (e.shiftKey) {
        if (e.keyCode === 188) {
          // ctrl + shift + , = reduce font size
          _toggleFontInlineStyle({ inlineStyle: 12, type: 'decrease-size', fontFamilies: [] });
          return 'handled';
        } else if (e.keyCode === 190) {
          // ctrl + shift + . = increase font size
          _toggleFontInlineStyle({ inlineStyle: 18, type: 'increase-size', fontFamilies: [] });
          return 'handled';
        } else if (e.keyCode === 69 || e.keyCode === 82 || e.keyCode === 76 || e.keyCode === 74) {
          // ctrl + shift + (e,j,l,r) = text alignment

          e.preventDefault();
          const align = {
            69: 'editor-align-center',
            74: 'editor-align-justify',
            76: 'editor-align-left',
            82: 'editor-align-right',
          };
          _toggleCustomBockStyle({ blockType: align[e.keyCode], type: 'align' });
          return 'handled';
        }
      }
    } else if (e.key === 'Tab' || e.keyCode === 9) {
      e.preventDefault();
      /*
      NOTE : dont allow user to enter multiple tab on list which will 
      make the bullets 1. and 1.0.1 or 1.0.0.1 (with 1 or 2 depth skip)
      */
      const selection = state.editorState.getSelection();
      const currentBlockKey = selection.getStartKey();
      const currentContent = state.editorState.getCurrentContent();

      const blockBefore = currentContent.getBlockBefore(currentBlockKey);
      const currentBlock = currentContent.getBlockForKey(currentBlockKey);

      /*
      if current block is list AND
      ( if previous block is not list OR
      current block depth > previous block depth dont allow tab )
      */
      if (
        currentBlock?.getType().endsWith('list-item') &&
        (!blockBefore.getType().endsWith('list-item') ||
          currentBlock?.depth > (blockBefore?.depth ?? 0))
      ) {
        return 'handled';
      }

      utils.onTab({
        e,
        editorRef,
        editorChange,
        editorState: state.editorState,
        props: { ...props, saveDraft },
        dispatch,
        onToggleCustomBockStyle: _toggleCustomBockStyle,
        tabType: e.shiftKey ? 'left-intent' : 'right-intent',
      });
      return 'handled';
    }

    // handle backscpae on selected text
    if (e.keyCode === 8) {
      const { editorState } = state;
      const selection = editorState.getSelection();
      if (!selection.isCollapsed()) {
        handleBackspace(editorState, selection);
        return 'handled'; // Indicate that the backspace command was handled
      }
    }

    return getDefaultKeyBinding(e);
  };

  // const saveTempDraft = proposalUtils.updateDraftHook(saveProposalDraft, setDisablePreview);

  const handleEditorDrop = (selection, e) => {
    if (dropDisabled) {
      return 'handled';
    }

    const elementType = e.getText();
    let newEditorState = EditorState.acceptSelection(state.editorState, selection);
    let insertAfterBlock = false;

    if (prop.importedProposal && elementType === 'add-text') {
      const endKey = selection.getEndKey();

      const selectedElement = document.querySelector(`[data-offset-key="${endKey}-0-0"]`); // get element where the new element is dropped
      const clientRect = selectedElement?.getBoundingClientRect();

      let newSelection;
      if (clientRect?.top > 0) {
        // add new element to the before the current section
        const { editorState, newBlockKey } = utils.insertEmptyBlock('before', newEditorState);

        newEditorState = editorState;

        newSelection = new SelectionState({
          anchorKey: newBlockKey,
          anchorOffset: 0,
          focusKey: newBlockKey,
          focusOffset: 0,
          isBackward: true,
          hasFocus: true,
        });
      } else {
        // add element after the current section
        newSelection = new SelectionState({
          anchorKey: endKey,
          anchorOffset: 1,
          focusKey: endKey,
          focusOffset: 1,
        });
        insertAfterBlock = true;
      }
      newEditorState = EditorState.forceSelection(newEditorState, newSelection);
    }
    // Can be used in future to move elements between sections
    // const dropSectionName = e.data.getData('sectionName');

    // if (dropSectionName) {
    //   const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);
    //   let tempEditorState = EditorState.createWithContent(
    //     convertFromRaw(prop.draft[dropSectionName].raw, extendedBlockRenderMap),
    //     decorator
    //   );
    //   const newTempEditorContentState = tempEditorState.getCurrentContent();
    //   const newTempEditorBlockMap = newTempEditorContentState.blockMap.delete(elementType);
    //   const newTempEditorContentWithoutEmptyBlock = newTempEditorContentState.set(
    //     'blockMap',
    //     newTempEditorBlockMap
    //   );
    //   const editorStateWithoutBlock = EditorState.push(
    //     tempEditorState,
    //     newTempEditorContentWithoutEmptyBlock,
    //     'remove-block'
    //   );

    //   saveTempDraft(dropSectionName, 'raw')(editorStateWithoutBlock, () => {});
    //   const block = tempEditorState.getCurrentContent().getBlockForKey(elementType);
    //   handleMoveBlock({ newEditorState, block, selection });
    // }

    const block = newEditorState.getCurrentContent().getBlockForKey(elementType);

    if (!block) {
      switch (elementType) {
        case 'add-text':
          handleTextInsert(newEditorState, insertAfterBlock);
          return 'handled';
        case 'add-divider':
          handleInsertDivider(newEditorState);
          return 'handled';
        case 'add-qoute':
          _toggleBlockType('blockquote', prop.importedProposal ? 'blockquote' : '', newEditorState);
          return 'handled';
        case 'add-image':
          handleImageInsert(newEditorState);
          return 'handled';
        case 'add-gallery':
          handleInsertGallery(newEditorState);
          return 'handled';
        case 'add-video':
          handleInsertVideo(newEditorState);
          return 'handled';
        case 'add-table':
          handleInsertTable(newEditorState);
          return 'handled';
        case 'add-testimonial':
          handleInsertTestimonial(newEditorState);
          return 'handled';
        case 'add-form':
          handleInsertForm(newEditorState);
          return 'handled';
        case 'add-price':
          handleInsertPriceMiles('price', newEditorState);
          return 'handled';
        case 'add-goals':
          handleInsertPriceMiles('miles', newEditorState);
          return 'handled';
        case 'add-html':
          handleInsertHTML(newEditorState);
          return 'handled';
        default:
          if (elementType?.startsWith('toolbar-library-media-')) {
            const src = e.data.getData('src');
            handleInsertFromMedia(src, newEditorState);
            return 'handled';
          }
          return false;
      }
    } else {
      handleMoveBlock({ newEditorState, block, selection });
      return 'handled';
    }
  };

  const handlePastedText = async (text, html) => {
    const selection = state.editorState.getSelection();
    const currentContent = state.editorState.getCurrentContent();

    if (html) {
      /* Todo: Fn:convertFromHTML allow as to parse entity and blocks. 
         using that we can convert our custom pricing and etc component. right now we converting it to text 
      */
      const blocks = convertFromHTML(html);

      const newContent = Modifier.replaceWithFragment(
        currentContent,
        selection,
        ContentState.createFromBlockArray(blocks, blocks.entityMap).getBlockMap()
      );

      const entityKeys = convertToRaw(newContent);

      if (entityKeys?.blocks?.length > 0) {
        const modifiedBlocks = entityKeys?.blocks?.map((block) => {
          if (block.type === 'atomic' && !block.entityRanges.length) {
            return {
              key: block.key,
              type: 'unstyled',
              text: block.text,
              depth: 0,
              inlineStyleRanges: [],
              entityRanges: [],
              data: {},
            };
          }
          return block;
        });

        const modifiedContent = convertFromRaw({
          entityMap: entityKeys.entityMap,
          blocks: modifiedBlocks,
        });

        const newSelection = new SelectionState({
          anchorKey: _.last(modifiedBlocks).key,
          anchorOffset: _.last(modifiedBlocks)?.text?.length || 0,
          focusKey: _.last(modifiedBlocks).key,
          focusOffset: _.last(modifiedBlocks)?.text?.length || 0,
          hasFocus: true,
        });

        const editorState = await EditorState.acceptSelection(
          EditorState.push(state.editorState, modifiedContent, 'insert-characters'),
          newSelection
        );
        handleEditorChange({
          es: editorState,
          loaderInActive: true,
        });
      }

      return true;
    }
    return false;

    /*
     * @todo: check
     *
    if (!selection.anchorOffset) {
      const newContent = Modifier.insertText(currentContent, selection, text);

      handleEditorChange({
        es: EditorState.push(state.editorState, newContent, 'insert-characters'),
      });
      return true;
    } else {
      return false;
    }
    */
  };

  const handleInsertVariable = (e) => {
    const text = `${e.keyPath[1]}.${e.keyPath[0]}}}`;
    const { editorState } = state;
    const selection = state.selectionState || editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const newContent = Modifier.insertText(contentState, selection, text);
    const withVariableText = EditorState.push(editorState, newContent, 'insert-characters');
    const withProperCursor = EditorState.forceSelection(
      withVariableText,
      newContent.getSelectionAfter()
    );
    handleEditorChange({ es: withProperCursor });

    dispatch({ type: 'setDisplayVariableInput', displayVariableInput: false });
    // dispatch({ type: 'setEditorReadOnly', editorReadOnly: false });
  };

  const handleGlobalClick = (e) => {
    if (state.displayColorPicker && state.editorReadOnly) {
      if (state.colorOpen) {
        // if updated in last 400ms then return no need to close
        // probably switched from COLOR to BACKGROUND
        if (state?.updated + 400 > Date.now()) {
          return;
        }

        if (e.target.id.indexOf('rc-editable-input') === -1) {
          closeColor();

          dispatch({
            type: 'setColorOpen',
            colorOpen: false,
          });
        }
      } else {
        dispatch({
          type: 'setColorOpen',
          colorOpen: true,
        });
      }
    }
    setActiveEditorId(id);
  };

  useEffect(() => {
    if (activeEditorId !== id) {
      closeColor();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeEditorId, id]);

  const handleRichEditorBlur = (event, type) => {
    // const { relatedTarget } = event;
    const relatedTarget =
      // document.getElementById(`r_${id}`)?.getElementsByClassName(event.target.className)?.[0];
      event.relatedTarget ||
      event.currentTarget ||
      event.explicitOriginalTarget ||
      document.activeElement;
    // console.log(relatedTarget, event.target.className);

    // console.log(event.target.className);
    // console.log(event.target.className, relatedTarget,
    //   // editorRef.current && !editorRef.current.contains(event.target),
    //   // relatedTarget?.className,
    //   // relatedTarget,
    //   // document.getElementById(`r_${id}`),
    //   // event.currentTarget,
    //   // event.relatedTarget,
    //   // event.explicitOriginalTarget,
    //   // document.activeElement,
    //   !event.target?.className?.match?.(/rich-editor/i) &&
    //     !event.target?.className?.match?.(/rich-editor-header/i) &&
    //     !event.target?.className?.match?.(/DraftEditor-root/i) &&
    //     !event.target?.className?.match?.(/DraftEditor-editorContainer/i) &&
    //     !event.target?.className?.match?.(/paragraph/i) &&
    //     !event.target?.className?.match?.(/DraftStyleDefault/i) &&
    //   !event.target?.className?.match?.(/input/i) &&
    //   !relatedTarget?.className?.match?.(/input/i) &&
    //     !event.target?.className?.match?.(/simple-section/i) &&
    //     !event.target?.className?.match?.(/ai-toolbar/i) &&
    //   !event.target?.className?.match?.(/ant-dropdown/i),
    //     // !event.target?.className?.match?.(/public-DraftEditor-content/i) &&
    //     !event.target?.closest(`.anticon`) &&
    //     // !event.target?.closest(`#r_${sectionName}_text`) &&
    //   !state.displayColorPicker,
    //   `${sectionName}_${simple}` === displayAIToolBar.current //&&
    //   ,
    //   'ok',
    //   // event.target?.closest(`#r_${sectionName}_text`),
    //   `${sectionName}_${simple}`, 'no', displayAIToolBar.current, 'po',
    //   displayAIToolBar.current && displayAIToolBar.current !== `${sectionName}_${simple}` && !state.aISelectionState,
    //   // event.target?.closest(`#r_${id}`)?.id,
    //   // `r_${id}`,
    //    event?.target?.closest(`#r_${id}`)?.id !== `r_${id}`
    // );
    if (
      (!event?.target?.className?.match?.(/rich-editor/i) &&
        !event?.target?.className?.match?.(/rich-editor-header/i) &&
        !event?.target?.className?.match?.(/DraftEditor-root/i) &&
        !event?.target?.className?.match?.(/DraftEditor-editorContainer/i) &&
        !event?.target?.className?.match?.(/paragraph/i) &&
        !event?.target?.className?.match?.(/DraftStyleDefault/i) &&
        !event?.target?.className?.match?.(/input/i) &&
        !event?.target?.className?.match?.(/simple-section/i) &&
        !event?.target?.className?.match?.(/ai-toolbar/i) &&
        !event?.target?.className?.match?.(/ant-dropdown/i) &&
        // !event?.target?.className?.match?.(/public-DraftEditor-content/i) && // check id with this
        !event?.target?.closest(`.anticon`) &&
        // !event?.target?.closest(`#r_${sectionName}_text`) &&
        !relatedTarget?.className?.match?.(/input/i) &&
        // !relatedTarget?.className?.match?.(/ant-dropdown-menu/i) &&

        !state.displayColorPicker) || //&&
      // &&
      // `${sectionName}_${simple}` === displayAIToolBar.current
      // !state.aISelectionState) ||
      !!(
        displayAIToolBar.current &&
        displayAIToolBar.current !== `${sectionName}_${simple}` &&
        !state.aISelectionState
      ) ||
      event?.target?.closest(`#r_${id}`)?.id !== `r_${id}` ||
      (event.target.className?.match?.(/ai-toolbar-input-text-area/i) && displayAIToolBar.current)
    ) {
      const contentIsntImg = content !== '<img />'; // Do not attempt AI open for img content
      let rawNew = convertToRaw(state?.editorState?.getCurrentContent()); // fixes reverting issue causes undo selection after AI open
      if (rawNew) {
        // const oldSelection = state?.editorState?.getSelection();
        console.log('89');
        let newEditorState = EditorState.createWithContent(
          convertFromRaw(rawNew, extendedBlockRenderMap),
          decorator
        );
        // const emptySelection = SelectionState.createEmpty(oldSelection?.getAnchorKey())
        //   .set(
        //   'hasFocus',
        //   false
        // );
        // let newEditorState = EditorState.acceptSelection(
        //   state.editorState,
        //   emptySelection
        // );
        displayAIToolBar.current = '';
        displayAIToolBarCursor.current = '';
        setAskAI(false);
        dispatch({ type: 'setAISelectionState', aISelectionState: '' });

        if (!isCursorAtEndOfBlock() && contentIsntImg) {
          newEditorState = _clearFormatting(newEditorState, false);
        }
        if (contentIsntImg) {
          const emptySelection = SelectionState.createEmpty().set('hasFocus', false);
          // const emptySelection = new SelectionState({
          //   anchorKey: currentSelection.getFocusKey(),
          //   anchorOffset: window.getSelection().focusOffset,
          //   focusKey: currentSelection.getFocusKey(),
          //   focusOffset: window.getSelection().focusOffset,
          //   hasFocus: false,
          //   isBackward: true
          // });
          newEditorState = EditorState.forceSelection(newEditorState, emptySelection);
        }
        editorRef.current = newEditorState;
        dispatch({ type: 'setEditorState', editorState: newEditorState });
        dispatch({
          type: 'setInlineToolbar',
          inlineToolbar: {
            show: false,
          },
        });
        // handleClickOutside();
      } else if (content) {
        console.log('88');

        let newEditorState = EditorState.createWithContent(
          stateFromHTML(content, htmlOptions || {}),
          decorator
        );
        displayAIToolBar.current = '';
        displayAIToolBarCursor.current = '';
        setAskAI(false);
        dispatch({ type: 'setAISelectionState', aISelectionState: '' });
        if (!isCursorAtEndOfBlock()) {
          newEditorState = _clearFormatting(newEditorState, false);
        }
        dispatch({ type: 'setEditorState', editorState: newEditorState });
        editorRef.current = newEditorState;
      }
    }
  };

  // This is for handling multiple selection inside a section
  const handleMouseDown = (event) => {
    // const relatedTarget =
    //   // document.getElementById(`r_${id}`)?.getElementsByClassName(event.target.className)?.[0];
    //   event.relatedTarget ||
    //   event.currentTarget ||
    //   event.explicitOriginalTarget ||
    //   document.activeElement;
    // console.log(event.target.className,
    //   event.target.closest('.ai-toolbar', 'class'));

    if (
      prop.language === 'english' &&
      ((!user?.profile?.enableAITool && prop?.enableAITool) ||
        (user?.profile?.enableAITool && prop?.enableAITool)) &&
      editorRef.current &&
      sectionName !== 'header' &&
      sectionName !== 'signature'
    ) {
      if (
        event.target.className === 'custom-select-selector' ||
        event.target.className === 'custom-select-item-content' ||
        event.target.className?.baseVal === 'toolbarSvgClear' ||
        event.target.className?.baseVal === 'ask-ai-icon-container' ||
        event.target.className?.baseVal === 'ask-ai-icon' ||
        event.target.className?.match?.(/ai-toolbar-bot/) ||
        event.target.className?.match?.(/rich-editor-toolbar-clear/) ||
        event.target.className?.match?.(/ask-ai-icon/) ||
        event.target.className?.match?.(/ant-dropdown-trigger/) ||
        event.target.className?.match?.(/ant-col/) ||
        event.target.className?.match?.(/ant-dropdown-menu/) ||
        event.target.closest('.ai-toolbar', 'class')
        // relatedTarget?.id === `r_${id}`
      ) {
        return;
      }

      const currentSelection = editorRef?.current?.getSelection();
      const previousSelection = state?.aISelectionState;
      // const windowsSelection = window.getSelection();

      if (event?.detail >= 3) {
        const selection = editorRef?.current?.getSelection();
        const anchorKey = selection?.getAnchorKey();
        const currentContent = editorRef?.current?.getCurrentContent();
        const currentBlock = currentContent?.getBlockForKey(anchorKey);

        if (!currentBlock) {
          return;
        }

        const contentState = ContentState.createFromBlockArray([currentBlock]);
        const ent = currentBlock.getEntityAt(0);

        if (ent) {
          const entity = contentState.getEntity(ent);
          const type = entity?.type;
          const data = entity?.data;

          if (
            data &&
            data.data &&
            (type === 'table' || type === 'testimonial' || type === 'form')
          ) {
            return;
          }
        }
        const selected = getFragmentFromSelection(editorRef?.current);

        let selectedText = selected ? selected.map((x) => x.getText()).join('\n') : '';

        const newSelection = new SelectionState({
          anchorKey: currentSelection.getAnchorKey(),
          anchorOffset:
            currentSelection.getAnchorOffset() === currentSelection.getFocusOffset()
              ? 0
              : currentSelection.getAnchorOffset(),
          focusKey: currentSelection.getFocusKey(),
          focusOffset: currentSelection.getFocusOffset() || selectedText?.length - 1, //currentSelection.getAnchorOffset(),
          hasFocus: true,
        });
        console.log('79');

        // commented in favor of showing AI popup at bottom of selected text
        let newEditorState = EditorState.acceptSelection(editorRef?.current, newSelection);
        dispatch({ type: 'setEditorState', editorState: newEditorState });
        dispatch({ type: 'setAISelectionState', aISelectionState: newSelection });
        if (!displayAIToolBar.current) {
          console.log('not showing a tool bar');
          newEditorState = formatBackgroundSelectionColor(newEditorState, true);
          displayAIToolBar.current = `${sectionName}_${simple}`;
        }
        editorRef.current = newEditorState;
        return;
      }

      if (currentSelection && previousSelection) {
        const currentSelectionAnchorOffset = currentSelection?.getAnchorOffset();
        const previousSelectionAnchorOffset = previousSelection?.getAnchorOffset();
        // const currentSelectionFocusOffset = currentSelection?.getFocusOffset();
        // const previousSelectionFocusOffset = previousSelection?.getFocusOffset();
        // console.log('23', event.target.className, event.target.className?.baseVal
        //   // , previousSelectionOffset, state.editorState.getSelection().getAnchorOffset(), currentSelection.getAnchorOffset(), '-='
        // );

        // console.log(currentSelectionAnchorOffset, previousSelectionOffset, windowsSelection.anchorOffset, '-=');
        if (
          // previousSelectionOffset === currentSelectionAnchorOffset &&
          // currentSelectionFocusOffset === previousSelectionFocusOffset &&
          // currentSelectionFocusOffset !== currentSelectionAnchorOffset // removed in favour of chrome single selection
          previousSelectionAnchorOffset !== currentSelectionAnchorOffset
        ) {
          // console.log('23');

          // handleRichEditorBlur(event, 'inside')
          handleClickOutside(null, 'inside', state?.aISelectionState);
        }
      }
    }
  };

  if (!editorRef.current) {
    return null;
  }
  const path = window.location.pathname;
  const isActive = state?.editorState?.getSelection().getHasFocus() || state.lineHeightInput;
  const uniqueKey = `${id}-${columnIndex}`;
  const canUseAI =
    (displayAIToolBar.current || displayAIToolBarCursor.current) &&
    ((!user?.profile?.enableAITool && prop?.enableAITool) ||
      (user?.profile?.enableAITool && prop?.enableAITool) ||
      wixEditor ||
      templateWixEditor) &&
    !state.displayLink &&
    sectionName !== 'header' &&
    sectionName !== 'signature' &&
    !state.displayVariableInput &&
    prop?.language === 'english' &&
    activeEditorId === id &&
    !/Mobi|Android|Tablet/i.test(navigator.userAgent) &&
    !commonHelper.isIOS() &&
    !commonHelper.isTablet();
  const aiStyle = _.extend({}, state.inlineToolbar.position);

  return (
    <div
      className={`rich-editor ${activeEditorId === id ? 'active' : 'inactive'}`}
      ref={richEditorRef}
      id={`r_${id}`}
      onClick={handleGlobalClick}
      onBlur={handleRichEditorBlur}
      onMouseUp={handleMouseDown}
      tabIndex="-1">
      <HyperLinkModals
        dispatch={dispatch}
        state={state}
        editor={editor}
        handleEditorChange={handleEditorChange}
        setShouldCheckUrl={setShouldCheckUrl}
        promptForLink={promptForLink}
        numberOfColumns={numberOfColumns}
        columnIndex={columnIndex}
        isValidUrl={isValidUrl}
        setIsValidUrl={setIsValidUrl}
      />
      {state.displayHyperlinkInput && (
        <HyperLinkSection
          dispatch={dispatch}
          {...state.displayHyperlinkInput}
          columnIndex={columnIndex}
          numberOfColumns={numberOfColumns}
          isRtl={isRtl}
          uniqueKey={uniqueKey}
        />
      )}

      {state.displayVariableInput && (
        <RenderVariableInput
          state={state}
          prop={prop}
          handleInsertVariable={handleInsertVariable}
          active={isActive && activeEditorId === props.id}
          toolbarPosition={toolbarPosition}
          setToolbarPosition={setToolbarPosition}
        />
      )}
      {canUseAI && (
        <AIToolBar
          id={id}
          style={aiStyle}
          askAI={askAI}
          editorState={editorRef.current}
          aiLengthMenu={config?.aiPrompt?.lengthListOptions}
          dispatch={dispatch}
          handleEditorChange={handleEditorChange}
          clearFormatting={_clearFormatting}
          language={prop?.language?.toLowerCase() || 'english'}
          setAskAI={setAskAI}
          displayAIToolBar={displayAIToolBar}
          displayAIToolBarCursor={displayAIToolBarCursor}
          editorRef={editorRef}
          formatBackgroundSelectionColor={formatBackgroundSelectionColor}
          aiSaving={aiSaving}
          zoomValue={zoomValue}
        />
      )}
      {type === 'header' && state.displayColorPicker && (
        <RenderColorPicker
          state={state}
          onToggle={_toggleInlineStyle}
          className="outside-toolbar"
        />
      )}

      <RichEditorModals
        prop={prop}
        user={user}
        config={config}
        state={state}
        dispatch={dispatch}
        handleEditorChange={handleEditorChange}
        saveProposal={saveProposal}
        saveUser={saveUser}
        setOpenVariablesList={setOpenVariablesList}
        focusEditor={focusEditor}
        checkSuspiciousUrl={checkSuspiciousUrl}
      />
      {activeEditorId === id && selectedBlock ? (
        <ToolBar
          // selectionActive={!state?.editorState?.getSelection().getHasFocus()}
          selectionActive={!state?.editorState?.getSelection().isCollapsed()}
          bodyFont={prop?.draft?.bodyFont || prop?.bodyFont}
          language={prop?.language?.toLowerCase() || 'english'}
          selectedBlock={selectedBlock}
          editorState={state.editorState}
          dispatch={dispatch}
          onToggle={_toggleInlineStyle}
          onToggleBlockType={_toggleBlockType}
          onToggleFontInlineStyle={_toggleFontInlineStyle}
          onToggleCustomBockStyle={_toggleCustomBockStyle}
          onClearFormatting={_clearFormatting}
          onSplitSection={_onSplitSection}
          zoomValue={zoomValue}
          user={user}
          displayColorPicker={state.displayColorPicker}
          colorPicker={
            type !== 'header' && (
              <RenderColorPicker
                state={state}
                onToggle={_toggleInlineStyle}
                className="inside-toolbar"
              />
            )
          }
          isSection={featureFlag?.DISABLE_SPLIT_FOR_SECTIONS ? path.includes('/s/') : false}
        />
      ) : null}
      {contentType === 'image' && !raw ? (
        <div className="image-drop-section">
          <div className="custom-dropzone" onDrop={onImageDrop} onDragOver={handleImageDragOver}>
            <input type="file" accept="image/*" onChange={handleImageChange} id="image-upload" />
            <label htmlFor="image-upload">Drop your Image Here</label>
          </div>
          {showImageProgress && (
            <div className="logo-upload-progress">
              <p className="progress-title">Uploading Image...</p>
              <p className="upload-image-name" title={imageName}>
                {imageName}
              </p>
              <div className="spacer" />
              <Slider value={uploadStatus.percent} tooltipVisible />
            </div>
          )}
        </div>
      ) : (
        <div
          onClick={focusEditor}
          id={`draft-editor-${id}`}
          // onBlur={handleRichEditorBlur}
          // onMouseDown={handleMouseDown}
          // NOTE : on adding onDragOver, effectAllowed = "none" wont work
          // onDragOver={(e) => e.preventDefault()}
          onDragEnter={(e) => {
            e.preventDefault();
            dragStartRef.current = true;
            if (dropDisabled) {
              e.dataTransfer.effectAllowed = 'none';
              if (!readOnly) {
                // make readonly
                setReadOnly(true);
              }
            }
          }}>
          <Editor
            ref={editor}
            readOnly={dropDisabled ? readOnly : state.editorReadOnly}
            spellCheck
            customStyleMap={state.styleMap}
            customStyleFn={customStyleFn}
            blockRenderMap={state.blockRenderMap}
            handleDrop={handleEditorDrop}
            // handleBeforeInput={handleBeforeInput}
            blockRendererFn={(block) =>
              blockRenderer({
                setElementsInSection,
                sectionType: contentType,
                numberOfColumns,
                setDraggingElement,
                sectionSpan,
                sectionName,
                block,
                state,
                wixEditor,
                templateWixEditor,
                dispatch,
                setDeletedBlockKeys,
                props: { ...props, saveDraft },
                editorRef,
                editorChange,
                upload,
                updateLoaderState,
                isEditingModal,
                uniqueKey,
                onEdit: (x, type) =>
                  onEdit(
                    {
                      v: x.v,
                      save: (nv, saveSuccess) => {
                        x.save(nv);
                        setTimeout(() => saveDraft(x.editorState, saveSuccess), 300);
                      },
                      cancel: x.cancel,
                    },
                    type
                  ),
                handleImageInsert,
                handleInsertVideo,
              })
            }
            blockStyleFn={blockStyler}
            editorState={editorRef.current}
            placeholder={placeholder || configText('write here', true)}
            handleKeyCommand={(command) =>
              utils.handleKeyCommand({
                command,
                editorState: state.editorState,
                editorRef,
                editorChange,
                props: { ...props, saveDraft },
                dispatch,
                createCellBlock,
                updateSelection: utils.updateSelection,
              })
            }
            keyBindingFn={handleKeyBindingFn}
            onChange={(editorState) => {
              const currentContentState = editorRef.current.getCurrentContent();
              const newContentState = editorState.getCurrentContent();

              if (
                prop.language === 'english' &&
                ((!user?.profile?.enableAITool && prop?.enableAITool) ||
                  (user?.profile?.enableAITool && prop?.enableAITool))
              ) {
                const currentSelection = editorRef?.current?.getSelection();
                const previousSelection = editorState?.getSelection();
                const currentSelectionOffset = currentSelection?.getAnchorOffset();
                const previousSelectionOffset = previousSelection?.getAnchorOffset();
                const currentSelectionFocusOffset = currentSelection?.getFocusOffset();
                const previousSelectionFocusOffset = previousSelection?.getFocusOffset();

                const oldSelected = getFragmentFromSelection(editorRef.current);
                const newSelected = getFragmentFromSelection(editorState);

                const newSelectedText = newSelected
                  ? newSelected.map((x) => x.getText()).join('\n')
                  : '';
                const oldSelectedText = oldSelected
                  ? oldSelected.map((x) => x.getText()).join('\n')
                  : '';

                const { language: proposalLangugae } = props?.prop;

                if (
                  state.aISelectionState &&
                  displayAIToolBar.current &&
                  currentContentState.getPlainText() === newContentState.getPlainText()
                ) {
                  updateSelectionInlineToolbar(props.id, proposalLangugae, dispatch);
                }

                if (
                  ((state.selectionState && displayAIToolBar.current) ||
                    (state.aISelectionState && displayAIToolBar.current)) &&
                  !(
                    previousSelectionOffset === currentSelectionOffset &&
                    currentSelectionFocusOffset !== previousSelectionFocusOffset
                  )
                ) {
                  if (
                    previousSelectionOffset === currentSelectionOffset &&
                    currentSelectionFocusOffset === previousSelectionFocusOffset
                  ) {
                    return;
                  } else if (newSelectedText && oldSelectedText !== newSelectedText) {
                    const selectionNew = editorState?.getSelection();
                    dispatch({ type: 'setAISelectionState', aISelectionState: selectionNew });
                    formatBackgroundSelectionColor(editorState);
                    return; // handleClickOutside(editorState);
                  } else if (!oldSelectedText && oldSelectedText !== newSelectedText) {
                    // handles drag, select and delete
                    return;
                  }
                }

                if (previousSelection?.getHasFocus()) {
                  const windowsSelection = window.getSelection();
                  const oldEditorStateSelection = state.editorState.getSelection();
                  let emptySelection;

                  if (
                    oldEditorStateSelection.getAnchorOffset() !==
                    oldEditorStateSelection.getFocusOffset()
                  ) {
                    console.log('01');

                    emptySelection = new SelectionState({
                      anchorKey: previousSelection.getAnchorKey(),
                      anchorOffset: previousSelection.getAnchorOffset(),
                      focusKey: previousSelection.getFocusKey(),
                      focusOffset: previousSelection.getFocusOffset(),
                      hasFocus: true,
                    });
                  } else {
                    console.log('02', id);
                    const previousSelectionAnchorOffset = previousSelection.getAnchorOffset();
                    const previousSelectionFocusOffset = previousSelection.getFocusOffset();
                    const previousSelectionAnchorKey = previousSelection.getAnchorKey();
                    const previousSelectionFocusKey = previousSelection.getFocusKey();
                    // if (!askAI) {

                    // reverse when we add hebrew

                    emptySelection = new SelectionState({
                      anchorKey: previousSelection.getAnchorKey(),
                      anchorOffset:
                        previousSelectionAnchorKey !== previousSelectionFocusKey
                          ? previousSelectionAnchorOffset
                          : previousSelectionAnchorOffset > previousSelectionFocusOffset
                          ? askAI
                            ? windowsSelection.focusOffset
                            : previousSelectionFocusOffset
                          : askAI
                          ? windowsSelection.anchorOffset
                          : previousSelectionAnchorOffset,
                      focusKey: previousSelection.getFocusKey(),
                      focusOffset:
                        previousSelectionAnchorKey !== previousSelectionFocusKey
                          ? previousSelectionFocusOffset
                          : previousSelectionAnchorOffset > previousSelectionFocusOffset
                          ? askAI
                            ? windowsSelection.anchorOffset
                            : previousSelectionAnchorOffset
                          : askAI
                          ? windowsSelection.focusOffset
                          : previousSelectionFocusOffset,
                      hasFocus: true,
                    });
                    // console.log(emptySelection, previousSelection, windowsSelection, '=-emptySelection');

                    editorState = EditorState.forceSelection(editorState, emptySelection);
                    editorRef.current = editorState;

                    // } else
                    // if (askAI) {
                    //   setAskAI(false);
                    //   displayAIToolBarCursor.current = '';
                    // }

                    // console.log('78', id, editorState.getSelection(), state.editorState.getSelection(),
                    //   windowsSelection.anchorOffset, windowsSelection.focusOffset,
                    //   previousSelection.getAnchorOffset(), previousSelection.getFocusOffset(),
                    //   currentSelection.getAnchorOffset(), currentSelection.getFocusOffset(),
                    //   state.editorState.getSelection().getAnchorOffset(), state.editorState.getSelection().getFocusOffset(),
                    //   state?.aISelectionState

                    // );
                  }
                }
              }

              return editorChange({
                editorState,
                props: { ...props, saveDraft },
                editorRef,
                dispatch,
                updateSelection: utils.updateSelection,
                promptForVariable,
                setOpenVariablesList,
                setScrollingTo,
                setActiveEditorId,
                focusChanged: currentContentState === newContentState,
              });
            }}
            handlePastedText={handlePastedText}
          />
        </div>
      )}

      <input
        type="file"
        ref={imageInputRef}
        style={{ display: 'none' }}
        onChange={insertImage}
        accept="image/*"
      />

      {
        /*
          in 2,3,4 column, when a  .ant-col is hovered its z-index is kept higher 
          then other .ant-col to show the image toolbar on top of other images
          when showing uploading overlay, 
          force unset z-index from .ant-col which causes flickering on hover 
          since the overlay is rendered inside .ant-col
        */
        uploadStatus.status === 'uploading' && (
          <style>
            {`.draft-editor .simple-section .simple-section-container .simple-section-content .ant-col  {
            z-index: unset!important;`}
          </style>
        )
      }

      {uploadStatus.status === 'uploading' && (
        <Progress className="rich-upload-loader" type="circle" percent={uploadStatus.percent} />
      )}
      {copyStatus && <Spin className="rich-copy-spinner" size="large" />}
    </div>
  );
};

RichEditor.defaultProps = {
  id: '',
  prop: '',
  raw: '',
  config: '',
  element: '',
  blockRenderMap: null,
  content: '',
  htmlOptions: '',
  sectionName: '',
  simple: '',
  placeholder: '',
  contentType: '',
  setElement: () => {},
  saveProposal: () => {},
  saveUser: () => {},
  zoomValue: 100,
  updateLoaderState: () => {},
  saveProposalDraft: () => {},
  setSavingDraftProposal: () => {},
  columnIndex: 0,
  numberOfColumns: 1,
  wixEditor: false,
  templateWixEditor: false,
  askAI: false,
  setAskAI: () => {},
};

RichEditor.propTypes = {
  id: PropTypes.string,
  element: PropTypes.string,
  prop: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.instanceOf(null)]),
  raw: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  config: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  blockRenderMap: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  content: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  htmlOptions: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  sectionName: PropTypes.string,
  simple: PropTypes.number,
  placeholder: PropTypes.string,
  linkTextValue: PropTypes.string,
  contentType: PropTypes.string,
  setElement: PropTypes.func,
  saveProposal: PropTypes.func,
  zoomValue: PropTypes.number,
  saveUser: PropTypes.func,
  updateLoaderState: PropTypes.func,
  saveProposalDraft: PropTypes.func,
  setSavingDraftProposal: PropTypes.func,
  setDisablePreview: PropTypes.func.isRequired,
  numberOfColumns: PropTypes.number,
  sectionSpan: PropTypes.number,
  titleSpan: PropTypes.number,
  setElementsInSection: PropTypes.func,
  wixEditor: PropTypes.bool,
  templateWixEditor: PropTypes.bool,
  askAI: PropTypes.bool,
  setAskAI: PropTypes.func,
};

export default RichEditor;

const RenderVariableInput = ({
  state,
  prop,
  handleInsertVariable,
  active,
  toolbarPosition,
  setToolbarPosition,
}) => {
  let position = {
    left: state.inlineToolbar?.position?.left || 0,
    right: state.inlineToolbar?.position?.right || 0,
    bottom: state.inlineToolbar?.position?.bottom || 0,
    top: state.inlineToolbar?.position?.top || 0,
  };

  // if left/right > 0, it means correct position, so save it for future use
  if ((position?.left > 0 || position?.right > 0) && !_.isEqual(position, toolbarPosition)) {
    setToolbarPosition({ ...position });
  }

  if (position?.left?.toolbarLeft < 0 || position?.right?.toolbarRight < 0) {
    // if left/right < 0, it means wrong position, so use position from cache
    position = { ...toolbarPosition };
  }
  console.log(position, state.inlineToolbar?.position, 'position');

  const variables = prop?.draft?.variables || ELEMENTS_VARIABLES;

  return (
    <Menu
      className={`variables-menu ${prop.language || 'english'}`}
      style={{
        position: 'absolute',
        zIndex: '10',
        width: '10em',
        color: '#05034D',
        boxShadow: '0px 5px 20px rgba(4, 3, 49, 0.15)',
        borderRadius: '4px !important',
        display: active ? 'block' : 'none',
        height: 'fit-content',
        ...position,
      }}
      onClick={(e) => handleInsertVariable(e)}
      expandIcon={<ChevronIcon className={`variables-menu-chevron-icon ${prop.language}`} />}>
      {Object.keys(variables).map((value) => {
        return (
          <SubMenu
            popupClassName={`variables-submenu-items ${prop.language}`}
            className={`variables-menu-items ${prop.language}`}
            key={value}
            title={value}>
            {Object.keys(variables[value]).map((variable) => {
              return (
                <Menu.Item className={`variables-menu-items ${prop.language}`} key={variable}>
                  {variable}
                </Menu.Item>
              );
            })}
          </SubMenu>
        );
      })}
    </Menu>
  );
};
